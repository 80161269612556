import React, { useEffect, useState } from 'react'
import Left_menu from './Left_menu'
import Header from './Header'
import Create_order_nav from './Create_order_nav'
import axios from 'axios'
import queryString from 'query-string';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
// import './customer_style.css';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';


import Swal from 'sweetalert2'

import ReactLoading from 'react-loading';
import config from "../../config"
import { useNetworkState } from 'react-use';
import Footer from '../Footer'

const Wallet_payment_link_show = (props) => {
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
    const location = useLocation();


    // console.log(location.state);

    return (
        <div>
            {/* <?php include 'meta.php';?> */}
            <Left_menu value={11} />
            {/* <?php include 'left_menu.php';?> */}

            <section class="home ">
                {/* <?php include 'header.php';?>  */}
                <Header />


                <section class="mb-3 mt-3 tools">
                    <div class="container">
                        <h3 class="py-3">Payment</h3>
                        <div class="row">

                            <iframe src={location.state.payment_url} height={500} width={500} />

                        </div>

                    </div>
                </section>

            </section>


            {/* <?php include 'footer.php';?> */}
            <Footer/>
        </div>
    )
}

export default Wallet_payment_link_show
