import React from 'react'

import Left_menu from './Left_menu'
import Header from './Header'
import Create_order_nav from './Create_order_nav'
import axios from 'axios'
import queryString from 'query-string';
import { useNavigate,useLocation } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
import { useNetworkState } from 'react-use';

// import './customer_style.css';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';


import Swal from 'sweetalert2'

import ReactLoading from 'react-loading';
import config from "../../config"
import Footer from '../Footer'
import { CornerLeftUp  , CornerUpLeft} from 'lucide-react'

const Settings_nav = (props) => {
    let value = props.value
    console.log("value == " , value)
    const[userstate , setUserState] = React.useState({})
  

    React.useEffect(()=>{
        user()
       
    },[])
    const user=()=>{
        let dataToSend = {
    };
     let url = config.apiUrl + '/user/find_customer_detail';
       axios.post(url, dataToSend, { headers: config.headers })
      .then((res) => {
        console.log("res ===" , res.data.output)
        setUserState(res.data.output)
          })
          .catch((error) => {
              console.log(error);
          });    
   }

   
  return (
    <div>
      
<h3><i class="bx bx-cog"></i> Settings</h3>
<ul class="nav  flex-column ps-4">
    <li class="nav-item">
        <a class={value==1?"nav-link active text-decoration-none":"nav-link text-decoration-none"} href="/customer/settings_main">Profile</a>
    </li>
    <li class="nav-item">
        <a class={value==2?"nav-link active text-decoration-none":"nav-link text-decoration-none"} href="/customer/settings_bank_details">Bank Detail  {userstate.aadhar_kyc==true?<span class="text-success"><i class="material-icons-outlined">verified</i></span>: <span class="text-danger"><i class="material-icons-outlined">error</i></span>}</a>
    </li>
    <li class="nav-item">
        <a class={value==3?"nav-link active text-decoration-none":"nav-link text-decoration-none"} href="/customer/settings_kyc_user">KYC Detail  {userstate.aadhar_kyc==true?<span class="text-success"><i class="material-icons-outlined">verified</i></span>: <span class="text-danger"><i class="material-icons-outlined">error</i></span>}</a>
    </li>
    <li class="nav-item">
        <a class={value==4?"nav-link active text-decoration-none":"nav-link text-decoration-none"} href="/customer/settings_billing_address">Billing Detail</a>
    </li>
    <li class="nav-item">
        <a class={value==5?"nav-link active text-decoration-none":"nav-link text-decoration-none"} href="/customer/settings_password">Change Password</a>
    </li>
</ul>
{/* <h3><i class="bx bx-cog"></i> Other Settings</h3>
<ul class="nav  flex-column ps-4">
    <li class="nav-item">
        <a class="nav-link text-decoration-none" href="settings_email.php">Email Notification</a>
    </li>
    <li class="nav-item">
        <a class="nav-link text-decoration-none" href="settings_sms.php">SMS Notification</a>
    </li>
    <li class="nav-item">
        <a class="nav-link text-decoration-none" href="settings_whatsapp.php">Whatsapp Notification</a>
    </li>
</ul>  */}
    </div>
  )
}

export default Settings_nav
