import React from 'react'
import Left_menu from './Left_menu'
import Header from './Header'
import Billing_nav from './Billing_nav'
import {  useNavigate } from 'react-router-dom'
// import Customer_header from './Customer_header'
import axios from "axios";
import Swal from 'sweetalert2';

import config from "../../config"
import { useNetworkState } from 'react-use';
import Footer from '../Footer'
import * as XLSX from 'xlsx'
const Payouts = () => {
    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
  
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
   
    const[balancestate, setbalanceState ]= React.useState({add_balance:[], total_balance:[]})
    const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
    const[transaction  , settransaction] = React.useState([])

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };
   React.useEffect(()=>{
    get_cod_wallet_balance()
    dimension_detail()
    cod_wallet_transactions()
   },[])

    const get_cod_wallet_balance=()=>{
     let dataToSend = {customer_id : userData.customer_id};
    
      // console.log("datatoSend",dataToSend)
      let url = config.apiUrl + '/user/get_cod_wallet_balance';
      axios.post(url, dataToSend, { headers: config.headers })
          .then((res) => {
            // console.log("responseJson => ", res);
            if(res.data.status==true){
        setbalanceState({add_balance:res.data.added_wallet_transactions, total_balance:res.data.find_customer_wallet})
     }
    else{
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000
          })
          Toast.fire({
            background:"rgb(231, 91, 75)",
            type: 'Unsuccess',
            title: "Something Went Wrong !",
            color:"white"
          });
    } })
        
          .catch((error) => {
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              Toast.fire({
                background:"rgb(231, 91, 75)",
                type: 'Unsuccess',
                title: "Something Went Wrong !",
                color:"white"
              });
              // console.log(error);
          });    
    
    }

   
      
    const dimension_detail = () =>{
        let full_api = config.apiUrl + `/user/get_company_info_api`;
        let sendData = {};
      
        axios.post(full_api, sendData, { headers: config.headers }).then(res => {
          setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
          // console.log("rnmmmmm", res);
        }).catch((e) => {
          // toast.configure()
           //toast.error("Some thing went wrong")
          // console.log("----error:   ", e);
        })
      }

      const cod_wallet_transactions=()=>{
        let dataToSend = {customer_id : userData.customer_id};
       
         // console.log("datatoSend",dataToSend)
         let url = config.apiUrl + '/shipment/get_cod_wallet_transactions_for_payouts';
         axios.post(url, dataToSend, { headers: config.headers })
             .then((res) => {
               // console.log("responseJson => ", res);
               if(res.data.status==true){
                settransaction(res.data.output)
        }
       else{
           const Toast = Swal.mixin({
               toast: true,
               position: 'bottom-end',
               showConfirmButton: false,
               timer: 3000
             })
             Toast.fire({
               background:"rgb(231, 91, 75)",
               type: 'Unsuccess',
               title: "Something Went Wrong !",
               color:"white"
             });
       } })
           
             .catch((error) => {
               const Toast = Swal.mixin({
                   toast: true,
                   position: 'bottom-end',
                   showConfirmButton: false,
                   timer: 3000
                 })
                 Toast.fire({
                   background:"rgb(231, 91, 75)",
                   type: 'Unsuccess',
                   title: "Something Went Wrong !",
                   color:"white"
                 });
                 // console.log(error);
             });    
       
       }

       const get_payout_detail=(payout_id)=>{
        let dataToSend = { payout_id:payout_id};
       
         // console.log("datatoSend",dataToSend)
         let url = config.apiUrl + '/user/payout_detail_api';
         axios.post(url, dataToSend, { headers: config.headers })
             .then((res) => {
                downloadExcel(res.data.shipments)
         })
           
             .catch((error) => {
               const Toast = Swal.mixin({
                   toast: true,
                   position: 'bottom-end',
                   showConfirmButton: false,
                   timer: 3000
                 })
                 Toast.fire({
                   background:"rgb(231, 91, 75)",
                   type: 'Unsuccess',
                   title: "Something Went Wrong !",
                   color:"white"
                 });
                 // console.log(error);
             });    
       
       }

       const downloadExcel = (shipments) => {
        const data = transformDataForExcel(shipments);
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Remittance Report');
        XLSX.writeFile(wb, 'Remittance.xlsx');
      };

      const transformDataForExcel = (shipments) => {
        return shipments.map(item => {
          console.log("created == ", item.created)
          const formattedDate = item.created
            ? new Date(item.created * 1000).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })
            : '';
    
          const formattedTime = item.created
            ? new Date(item.created * 1000).toLocaleTimeString('en-US', {
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
              hour12: true,
            })
            : '';
          return {
            'Tracking Id': item?.carrier_tracking_number || '',
            
            'Receiver Name': item?.receiver[0]?.name || '',
            'Address': item?.receiver[0]?.address || '',
            // 'Mobile Number': item?.receiver[0]?.mobile || '',
            'City': item?.receiver[0]?.city || '',
            'State': item?.receiver[0]?.state || '',
            'Pincode': item?.receiver[0]?.pincode || '',
            'Carrier': item?.carrier_name || '',
            'Mode':  item?.product_type_name || '',
            'Carrier Product': item?.service_provider || '',
           
            'Weight (kg)': item?.total_weight || '',
            'L': item?.length || '',
            'B':item?.width || '',
            'H':item?.height || '',
            'Volumetric Weight (kg)': item?.volumetric_weight || '',
            'Chargeable Weight': item?.dead_weight || '',
            'Shipping Fee (₹)': item.weight_discrepancies==0?item.total_amount:item.discrepencies_amount,
            'Shipment type': item?.type || '',
            'Payment Mode': item?.payment_mode === 'cod' || item?.payment_mode === 'cash' ? 'COD' : item?.payment_mode || '',
            'COD Amount': item?.cod_amount || '',
            'Delivery Date':item?.delivery_date||'',
            'Date': formattedDate,
            'Time': formattedTime,
          }
        });
      };
     
  return (
    <div>
          {/* <?php include 'meta.php';?> */}
    
    {/* <?php include 'left_menu.php';?> */}
    <Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <div className={`home-section ${isSidebarOpen ? '' : 'sidebar-closed'}`}>
           
            <Header/>
        

        {/* <?php include 'billing_nav.php';?>    */}
<Billing_nav value={10}/>
        <section class="mb-3">
          <div class="">
            <div class="card border-0">
              <div class="card-body">
                <div class="row states stboxbil py-3">
                    <div class="col-12 col-md-2 mb-3">
                        <div class="stbox text-center py-4 border rounded  ">
                        {balancestate.total_balance.map((sub)=>( <h5>&#8377;{parseFloat(sub.cod_wallet_balance).toFixed(2)}</h5>))}
                            <small>COD Wallet <a href="#" data-bs-toggle="popover" data-bs-placement="top" data-bs-trigger="hover" data-bs-content="Amount to be remitted in next cycle" class="text-muted"><i class="fa fa-question-circle-o" aria-hidden="true"></i></a></small>
                        </div>
                    </div>
                    {/* <div class="col-12 col-md-2 mb-3">
                        <div class="stbox text-center py-4 border rounded ">
                            <h5>&#8377;0.00</h5>
                            <small>Last COD Remitted</small>
                        </div>
                    </div>
                    <div class="col-12 col-md-2 mb-3">
                        <div class="stbox text-center py-4 border rounded ">
                            <h5>&#8377;0.00</h5>
                            <small>Total COD Remitted</small>
                        </div>
                    </div>
                    <div class="col-12 col-md-2 mb-3">
                        <div class="stbox text-center py-4 border rounded ">
                            <h5>&#8377;0.00</h5>
                            <small>Total deduction from COD</small>
                        </div>
                    </div>
                    <div class="col-12 col-md-2 mb-3">
                        <div class="stbox text-center py-4 border rounded ">
                            <h5>&#8377;0.00</h5>
                            <small>Remittance Initiated</small>
                        </div>
                    </div> */}
                </div>
              </div>
            </div>     
          </div>
        </section>

        <section class="mb-3">
          <div class="container">
            <div>
                {/* <div class="input-group mb-3 input-group-sm">
                    <input type="date" class="form-control" placeholder="Search by Order ID"/>
                    <select class="form-select">
                      <option>Last one year</option>
                      <option>2022</option>
                      <option>2021</option>
                      <option>2020</option>
                    </select>
                    <input type="text" class="form-control w-25" placeholder="Search by Order ID"/>
                    <button class="btn btn-primary" type="submit">Search</button>
                </div> */}
            </div>
            <div class=" table-responsive">
                <table class="table table-bordered table_box billing_table">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Transaction Id</th>
                        {/* <th>Shipment Id</th> */}
                        <th>Payout ID</th>
                        <th>Method</th>
                        <th>Amount</th>
                        <th>Status</th>
                        <th>Download</th>

                         </tr>
                    </thead>
                    <tbody>
                      {transaction.map((sub)=>(
                      <tr>
                      <td>{sub.date}</td>
                      <td>{sub.transaction_id}</td>
                      {/* <td>{sub.shipment_id}</td> */}
                      <td> <a className='text-decoration-none' href={`/customer/transaction_detail/${sub.payout_id}`} style={{cursor:"pointer"}}>{sub.payout_id?sub.payout_id:""}</a> </td>

                      <td>{sub.payment_method}</td>

                      <td>{sub.amount}</td>
                      <td>{sub.payment_status==2?<span class="badge rounded-pill bg-success fw-400">Success</span>:sub.payment_status==3?<span class="badge rounded-pill bg-warning fw-400">Under Process</span>:""}</td>
                      <td><button  className="btn btn-primary btn-sm" onClick={(e)=>get_payout_detail(sub.payout_id)}>Download Excel</button></td>

                      </tr>
                      ))}
                     
                      {/* <tr>
                        <td colspan="12" class="text-center">
                          <p>Your remittance is on its way</p>
                          <small>We release COD remittance 3 times in a week and on the 8th day from the date of delivery.</small>
                        </td>
                      </tr> */}
                    </tbody>
                </table>  
            </div>
          </div>
        </section>
        
        
    

    </div>
    {/* <?php include 'footer.php';?> */}
    <Footer/>
    </div>
  )
}

export default Payouts
