import React from 'react'
import Left_menu from './Left_menu'
import Header from './Header'
import { useNavigate, useParams } from 'react-router-dom';
import axios from "axios";
import config from "../../config"

import { useNetworkState } from 'react-use';
import Footer from '../Footer';
const Bulk_order_log = () => {
const{log_id} = useParams() 
const isOnline = useNetworkState();
console.log("isONline  === " ,isOnline )
const[state,setState]= React.useState([])
const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
const [progress, setProgress] = React.useState(null);
const [showorderBar, setShoworderBar] = React.useState(true);

const [statusstateorder, setstatusorder] = React.useState({status:""});

const [completedOrdersbulk, setCompletedOrdersbulk] = React.useState(0);
const [countOrdersbulk, setCountOrdersbulk] = React.useState(false);

const [uploaded, setuploaded] = React.useState(0);
const [total, settotal] = React.useState(0);

const [totalOrdersbulk, setTotalOrdersbulk] = React.useState(0);
const [logidstate, setlogId] = React.useState(null);

let userDetail = localStorage.getItem('ship_rocket_user');
// console.log("userDetail====", config.UserId);
let userData = {};
if (userDetail != null) {
  userData = JSON.parse(userDetail)
}

const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    const homeSection = document.querySelector('.home-section');
    if (homeSection) {
        homeSection.classList.toggle('sidebar-closed');
    }
};
    const shipment_list=(status)=>{
        let dataToSend = {log_id : log_id
    };
   
      // console.log("datatoSend",dataToSend)
      let url = config.apiUrl + '/shipment/bulk_shipment_log_view';
     
     // console.log("headers => ", config.headers);
     
      
      axios.post(url, dataToSend, { headers: config.headers })
          .then((res) => {
            // console.log("responseJson => ", res);
            setState(res.data.output)
            // setisLoading(false)
            // if(res.data.output[0].local_uploaded_records!=res.data.output[0].total_records){
            //   summary_record()
            // }
         
          })
          .catch((error) => {
              //Hide Loader
            //   setLoadingstate({...loadingstate,loading:false})
              // console.log(error);
          });    

    }

    React.useEffect(()=>{
        shipment_list()
    },[])


    // React.useEffect(()=>{
    //   const storedState = JSON.parse(localStorage.getItem('orderBarState'));
    //   console.log("storedState bulk == " , storedState)
    //   if (storedState && storedState.logId == log_id) {
    //     // setShoworderBar(storedState.showordersBar);
    //     setCompletedOrdersbulk(storedState.completedOrdersbulk);
    //     setTotalOrdersbulk(storedState.totalOrdersbulk);
    //     setlogId(storedState.logId);
    //     setstatusorder({status:storedState.completedOrdersbulk==storedState.totalOrdersbulk?"finished":"pending"});
    //   }
    //      if(storedState!=null){
    //   if(storedState.completedOrdersbulk && storedState.totalOrdersbulk && storedState.completedOrdersbulk !=storedState.totalOrdersbulk){
    //     let url = config.apiUrl + '/shipment/view_summary_bulk_order_record?customer_id=' + userData.customer_id + '&log_id=' + log_id + '';
      
    //     axios({
    //       method: "get",
    //       url: url,
    //       headers: config.headers,
    //       responseType: "stream",
    //       onDownloadProgress: progressEvent => {
    //         // console.log("progressEvent ", progressEvent.currentTarget.response);
    //         let parseData = progressEvent.currentTarget.response;
    //         parseData = parseData.split("Z");
    //         parseData = JSON.parse(parseData[parseData.length - 1]);
    
    //         console.log("parsedData=====",parseData)
  
           
    //        if(parseData.status=="finished"){
    //         // setShoworderBar(storedState. )
    //         setCompletedOrdersbulk(parseData.uploaded_record)
    //         setTotalOrdersbulk(parseData.total_record)
    //         setstatusorder({...statusstateorder,status:"finished"})
    //         localStorage.setItem('orderBarState', JSON.stringify({
    //           // showordersBar:true,
    //           completedOrdersbulk:parseData.uploaded_record,
    //          logId:log_id,
    //          totalOrdersbulk:parseData.total_record,
    //          statusbulk: "finished"
    //         }));
    //       }
          
    //         // console.log(state);
    //         // setStateRecord({ uploaded_record: parseData.uploaded_record, total_record: parseData.total_record, status: parseData.status })
    
    
    //       }
    //     }).then((res) => {
    //       //  console.log("responseJson11 => ", res);
    //       // setState(res.data.output)
    
    //     })
    //   }
    //      }
    // },[])
    // const summary_record = () => {
    //   console.log("summary_record called")
     
     
    //   let url = config.apiUrl + '/shipment/view_summary_bulk_order_record?customer_id=' + userData.customer_id + '&log_id=' + log_id + '';
  
    //   // // console.log("headers => ", config.headers);
    //   axios({
    //     method: "get",
    //     url: url,
    //     headers: config.headers,
    //     responseType: "stream",
    //     onDownloadProgress: progressEvent => {
    //       // console.log("progressEvent ", progressEvent.currentTarget.response);
    //       let parseData = progressEvent.currentTarget.response;
    //       parseData = parseData.split("Z");
    //       parseData = JSON.parse(parseData[parseData.length - 1]);
  
    //       console.log("parsedData=====",parseData)

    //       if(parseData.status!="pending"){
         
    //       // setUploadState({ ...uploadState, status: "" })
    //       // setShoworderBar(true )
    //       setCountOrdersbulk(false)
    //       setCompletedOrdersbulk(parseData.uploaded_record)
    //       setTotalOrdersbulk(parseData.total_record)
    //       setstatusorder({...statusstateorder,status:"finished"})
    //       setlogId(log_id)
    //       setuploaded(parseData.uploaded_record)
    //       localStorage.setItem('orderBarState', JSON.stringify({
    //         // showordersBar:true,
    //         completedOrdersbulk:parseData.uploaded_record,
    //         logId:log_id,
    //        totalOrdersbulk:parseData.total_record,
    //        statusbulk: "finished"
    //       }));
    //     }else{
         
    //       // setShoworderBar(true )
    //       setCountOrdersbulk(true)
    //       setCompletedOrdersbulk(parseData.uploaded_record)
    //       setTotalOrdersbulk(parseData.total_record)
    //       setstatusorder({...statusstateorder,status:"pending"})
    //       setlogId(log_id)
    //       localStorage.setItem('orderBarState', JSON.stringify({
    //         // showordersBar:true,
    //         completedOrdersbulk:parseData.uploaded_record,
    //        logId:log_id,
    //        totalOrdersbulk:parseData.total_record,
    //        statusbulk: "pending"
    //       }));
    //     }
    //       // console.log(state);
    //       // setStateRecord({ uploaded_record: parseData.uploaded_record, total_record: parseData.total_record, status: parseData.status })
  
  
    //     }
    //   }).then((res) => {
    //     //  console.log("responseJson11 => ", res);
    //     // setState(res.data.output)
  
    //   })
  
    // }
   

   

  return (
    <div className='wrapper'>
   <Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <div className={`home-section ${isSidebarOpen ? '' : 'sidebar-closed'}`}>
         
          <Header/>

        <section class="mb-3 pt-4">
          <div class="">
            <div class="row mb-3">
              <div class="col-12 col-md-3">
                <h3><a href="/customer/orders"><i class="fa fa-chevron-left" aria-hidden="true"></i></a>  Bulk Orders Log</h3>
              </div>
            </div> 

            
               
          </div>
        </section>


        <section class="mb-3">
          <div class="">
            <div class="table-responsive">
                <table class="table table_box">
                    <thead  class="">
                        <tr class="brd">
                        <th>Log ID</th>
                        <th>Date/Time</th>
                        <th>Orders</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                        {state.map((sub)=>(

                       
                        <tr class="brd">
                            <td>
                                <h5><a href={`/customer/bulk_orders_log_detail/${sub.log_id}`}>{sub.log_id}</a></h5>
                            </td>
                            <td>
                             <small>{sub.date} | {sub.time}</small>
                            </td>
                            <td>
                            {/* <h2><strong>{completedOrdersbulk==0?sub.local_uploaded_records:completedOrdersbulk}</strong><small class="text-primary">/{totalOrdersbulk==0?sub.total_records:totalOrdersbulk}</small></h2> */}
                            <h2><strong>{sub.local_uploaded_records}</strong><small class="text-primary">/{sub.total_records}</small></h2>
                               
                            </td>
                            <td>
                                {/* {completedOrdersbulk==0 ?
                                sub.status!=1?
                                <h2><span class="badge rounded-pill bg-info fw-400">Running</span></h2>:
                                <h2><span class="badge rounded-pill bg-success fw-400">Completed</span></h2>:
                                completedOrdersbulk!=0&&  completedOrdersbulk!=totalOrdersbulk?
                                <h2><span class="badge rounded-pill bg-info fw-400">Running</span></h2>:
                                <h2><span class="badge rounded-pill bg-success fw-400">Completed</span></h2>
                                } */}
                                { sub.local_status!=1?
                                <h2><span class="badge rounded-pill bg-info fw-400">Running</span></h2>:
                                <h2><span class="badge rounded-pill bg-success fw-400">Completed</span></h2>}
                            </td>
                            <td>
                                <h5><a href={`/customer/bulk_orders_log_detail/${sub.log_id}`}>View Log</a></h5>
                            </td>
                        </tr>
                         ))}
                    </tbody>
                </table>
            </div>     
          </div>
        </section>
        
    

    </div>
    {/* <?php include 'footer.php';?> */}
    <Footer/>
    </div>
  )
}

export default Bulk_order_log
