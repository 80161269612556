import React from 'react'
import Settings_nav from './Settings_nav'
import Left_menu from './Left_menu'
import Header from './Header'
import Create_order_nav from './Create_order_nav'
import axios from 'axios'
import queryString from 'query-string';
import { useNavigate,useLocation } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
import { useNetworkState } from 'react-use';

// import './customer_style.css';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';


import Swal from 'sweetalert2'

import ReactLoading from 'react-loading';
import config from "../../config"
import Footer from '../Footer'
import { CornerLeftUp  , CornerUpLeft} from 'lucide-react'

const Settings_password = () => {
    const[state,setstate]=React.useState({current_password:"" , new_password:"" , confirm_password:""})
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };

    const handleChange =(e)=>{
      setstate({...state,[e.target.name]: e.target.value})
    }

    const update =()=>{
        let full_api = config.apiUrl + `/user/update_password`;
        let sendData = { state };

        if (state.current_password!="" && state.new_password!="" && state.confirm_password!="") {
         console.log("bb", sendData);
        axios.post(full_api, sendData, { headers: config.headers }).then((res) => {
            if(res.data.status==true){
                setstate({...state , current_password:"" , new_password:"" , confirm_password:""})
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 2000
                  })
                Toast.fire({
                    background: "#082D79",
                    type: 'success',
                    title: res.data.message,
                    color: "white"
                });
            }
            else{
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 2000
                  })
                Toast.fire({
                    background: "#e63900",
                    type: 'error',
                    title: res.data.message,
                    color: "white"
                });
            }
           
        }).catch((e) => {
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 2000
              })
            Toast.fire({
                background: "#e63900",
                type: 'error',
                title: "Something Went Wrong",
                color: "white"
            });

        });
    }else{
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 2000
          })
        Toast.fire({
            background: "#e63900",
            type: 'error',
            title: "Please Fill all details",
            color: "white"
        });

    }
    }
  return (
    <div>
     <Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <div className={`home-section ${isSidebarOpen ? '' : 'sidebar-closed'}`}>
          <Header/>
     <div class="row">
                    <div class="col-12 col-md-3 left_sub_menu_bx">
                        {/* <?php include 'settings_nav.php';?> */}
                        <Settings_nav value={5}/>
                    </div>
                    <div class="col-12 col-md-9">
                        <div class="row mb-2">
                            <div class="col-12 col-md">
                                <div class="d-flex">
                                    <div class="back_btn_wrap">
                                        <a href="" class="back_btn">
                                        <CornerUpLeft/>
                                        </a>
                                    </div>
                                    <div class="">
                                        <h2>Change Password</h2>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-5 text-end btns">

                            </div>
                        </div>

                        <div class="card border-0 shadow-sm">
                          <div class="card-body">
                            <div class="row">
                                    <div class="col-12 col-md-6 mb-3">
                                        <label for="" class="form-label">Current Password <span class="text-danger">*</span></label>
                                        <input type="password" class="form-control" id="text"  name="current_password" placeholder="Enter Current Password" onChange={(e)=>handleChange(e)}/>
                                    </div>
                                    <div class="col-12 col-md-6 mb-3">
                                        
                                    </div>
                                    <div class="col-12 col-md-6 mb-3">
                                        <label for="" class="form-label">New Password <span class="text-danger">*</span></label>
                                        <input type="password" class="form-control" id="text" name="new_password" placeholder="Enter New Password" onChange={(e)=>handleChange(e)}/>
                                    </div>
                                    <div class="col-12 col-md-6 mb-3">
                                        <label for="" class="form-label">Confirm New Password <span class="text-danger">*</span></label>
                                        <input type="password" class="form-control" id="text" name="confirm_password" placeholder="Confirm New Password" onChange={(e)=>handleChange(e)} />
                                    </div>
                            </div>

                            <a onClick={(e)=>update(e)} class="btn btn-primary">Update</a>
                          </div>
                        </div>
                </div>
            </div>
            
            

                

        </div>
    
    <Footer/>

    </div>
  )
}

export default Settings_password
