import React, { useState, useEffect } from 'react'
import Left_menu from './Left_menu'
import Header from './Header'
import axios from 'axios'
import config from "../../config"
import Footer from '../Footer'


const Aftership_tracking_script = () => {
    const [buttonBgColor, setButtonBgColor] = React.useState('#0d6efd');
  const [buttonTextColor, setButtonTextColor] = React.useState('#ffffff');
  const [trackingBgColor, settrackingBgColor] = React.useState('#CECECE');
  const [trackingtitleColor, settrackingtitleColor] = React.useState('#210303');
  const [trackingbuttonBgColor, settrackingButtonBgColor] = React.useState('#0d6efd');
  const [trackingbuttontextColor, settrackingButtontextColor] = React.useState('#ffffff');
  const [trackinginputboxColor, settrackinginputboxColor] = React.useState('#ffffff');
  const [trackinginputtextColor, settrackinginputtextColor] = React.useState('#ffffff');
  const [copied, setCopied] = React.useState(false);
  const [copiedtracking, setCopiedtracking] = React.useState(false);
  const[url , seturl] = React.useState()
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

  const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
      const homeSection = document.querySelector('.home-section');
      if (homeSection) {
          homeSection.classList.toggle('sidebar-closed');
      }
  };

 React.useEffect(()=>{
    checkurl()
 },[])
 
  const checkurl = () => {
   
 let url = config.apiUrl + '/user/after_ship_details_for_tracking_script';
    let sendData = {};
    // console.log("bb", sendData);
    axios.post(url, sendData, { headers: config.headers }).then((res) => {
        console.log("res ", res);  
        seturl(res.data.output)
     
    }).catch((e) => {
      console.log("e === " , e)
    });
}
  // Function to handle color changes
  const handleColorChange = (type, color) => {
    if (type === 'bg') {
      setButtonBgColor(color);
    } else if (type === 'text') {
      setButtonTextColor(color);
    }
    else if(type === 'tracking-bg'){
        settrackingBgColor(color)
    }
    else if(type==='tracking-title'){
        settrackingtitleColor(color)
    }
    else if(type==='tracking-btn'){
        settrackingButtonBgColor(color)
    }
    else if(type === 'tracking-btn-text'){
        settrackingButtontextColor(color)
    }
    else if(type === 'tracking-input-box'){
        settrackinginputboxColor(color)
    }
    else if (type === 'tracking-input-text'){
        settrackinginputtextColor(color)
    }
  };
  const generateButtonCode = () => {
    return `
   
<a href="#" style="
background: ${buttonBgColor};
color: ${buttonTextColor};
padding: 10px 20px;
border-radius: 20px;
font-size: 16px;
text-decoration: none;
display: inline-block;
">Track</a>

    `;
  };
  const generateTrackingCode=()=>{
    
  
    return`
  
    <div style="background: ${trackingBgColor}; padding: 70px; text-align: center; border-radius: 8px;">
        <div style="background: #fff; padding: 20px;">
            <div style="background: #fff; padding: 20px; text-align: center;">
                <h5 style="font-size: 18px; margin-bottom: 14px; color:  ${trackingtitleColor}">Track Your Order</h5>
                <form action=${url} method="get" target="_blank">
                    <input type="text" class="" name="number" placeholder="Enter AWB Number" style="margin-bottom: 20px; width: 100%; border-radius: 20px; border: 1px solid #d5d5d5; padding: 9px; color: ${trackinginputtextColor}; background: ${trackinginputboxColor};" />
                    <div>
                        <button type="submit" class="" style="background: ${trackingbuttonBgColor}; color: ${trackingbuttontextColor}; padding: 10px 20px; border-radius: 20px; font-size: 16px; text-decoration: none;">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
`

  }
  const copyButtonCode = () => {
    const buttonCodeElement = document.getElementById('buttonCode');
    const range = document.createRange();
    range.selectNode(buttonCodeElement);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
    setCopied(true);

    // Reset copied state after a brief timeout
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };
  const copyTrackingCode = () => {
    const buttonCodeElement = document.getElementById('trackingCode');
    const range = document.createRange();
    range.selectNode(buttonCodeElement);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
    setCopiedtracking(true);

    // Reset copied state after a brief timeout
    setTimeout(() => {
        setCopiedtracking(false);
    }, 2000);
  };
  return (
    <div>
        <Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <div className={`home-section ${isSidebarOpen ? '' : 'sidebar-closed'}`}>
         
          <Header/>
       <section class="mb-3 mt-3 tools">
          <div class="container">
            <h3 class="pt-3">After Ship Settings</h3>
                
            <ul class="nav nav-pills">
                <li class="nav-item">
                    <a class="nav-link active" href="/customer/aftership">Tracking Page Settings</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="/customer/aftership_tracking_script">Tracking Script Settings</a>
                </li>
            </ul> 
            <div class="row mt-4 mb-3">
                    <div class="col-12 col-md-3">
                    <h5>Tracking Button Settings</h5>
                    <p>Customise the order tracking button as per your choice for a better brand recall value and enhanced customer experience.</p>
                    </div>
                    <div class="col-12 col-md">
                        <div class="card border-0 shadow-sm mb-3">
                          <div class="card-body">
                            <h6 class="pb-4">Tracking Button Settings</h6>
                            <div class="row">
                                <div class="col-12 col-md">
                                    <div class="row">
                                        <div class="col-12 col-md-12 mb-3">
                                            <label for="email" class="form-label">Button Background Colour:</label>
                                            <input type="color" class="form-control form-control-lg" id="" placeholder="Enter Background Colour"  name="buttonBgColor"
              value={buttonBgColor}
              onChange={(e) => handleColorChange('bg', e.target.value)}/>
                                        </div>
                                        <div class="col-12 col-md-12 mb-3">
                                            <label for="email" class="form-label">Button Text Colour:</label>
                                            <input type="color" class="form-control form-control-lg" id="" placeholder="Enter Button Text Colour" name="buttonTextColor"
              value={buttonTextColor}
              onChange={(e) => handleColorChange('text', e.target.value)}/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-8">
                                    <div class="card bg-light p-5 text-center">
                                      <div class="card-body">
                                      <h6 class="mb-3">Tracking Button Preview</h6>
                                      <a href="#" style={{ background: buttonBgColor,
                color: buttonTextColor,
                                      padding: "10px 20px",
                                      borderRadius: "20px",
                                      fontSize: "16px",
                                      textDecoration:"none"}}>Track</a>
                                      </div>
                                    </div>
                                </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
                
                <div class="row mt-4 mb-3">
                    <div class="col-12 col-md-3">
                    <h5>Pop-Up Settings</h5>
                    <p>Send pop-up notifications to your customers in a customised display box.</p>
                    </div>
                    <div class="col-12 col-md">
                        <div class="card border-0 shadow-sm mb-3">
                          <div class="card-body">
                            <h6 class="pb-4">Pop-Up Settings</h6>
                            <div class="row">
                                <div class="col-12 col-md">
                                    <div class="row">
                                        <div class="col-12 col-md-12 mb-3">
                                            <label for="email" class="form-label">Background Colour:</label>
                                            <input type="color" class="form-control form-control-lg" id="" placeholder="Enter Background Colour"  name="buttonTextColor"
              value={trackingBgColor}
              onChange={(e) => handleColorChange('tracking-bg', e.target.value)} />
                                        </div>
                                        <div class="col-12 col-md-12 mb-3">
                                            <label for="email" class="form-label">Text Colour (Title: Track Your Order):</label>
                                            <input type="color" class="form-control form-control-lg" id="" placeholder="Enter Text Colour  (Title: Track Your Order)"  name="buttonTextColor"
              value={trackingtitleColor}
              onChange={(e) => handleColorChange('tracking-title', e.target.value)}/>
                                        </div>
                                        <div class="col-12 col-md-12 mb-3">
                                            <label for="email" class="form-label">Button Background Colour:</label>
                                            <input type="color" class="form-control form-control-lg" id="" placeholder="Enter Button Background Colour" name="buttonTextColor"
              value={trackingbuttonBgColor}
              onChange={(e) => handleColorChange('tracking-btn', e.target.value)}/>
                                        </div>
                                        <div class="col-12 col-md-12 mb-3">
                                            <label for="email" class="form-label">Button Text Colour:</label>
                                            <input type="color" class="form-control form-control-lg" id="" placeholder="Enter Button Text Colour" name="buttonTextColor"
              value={trackingbuttontextColor}
              onChange={(e) => handleColorChange('tracking-btn-text', e.target.value)}/>
                                        </div>
                                        <div class="col-12 col-md-12 mb-3">
                                            <label for="email" class="form-label">Input Box Colour:</label>
                                            <input type="color" class="form-control form-control-lg" id="" placeholder="Enter Input Box Colour" name="buttonTextColor"
              value={trackinginputboxColor}
              onChange={(e) => handleColorChange('tracking-input-box', e.target.value)}/>
                                        </div>
                                        <div class="col-12 col-md-12 mb-3">
                                            <label for="email" class="form-label">Input Text Colour:</label>
                                            <input type="color" class="form-control form-control-lg" id="" placeholder="Enter Input Text Colour" name="buttonTextColor"
              value={trackinginputtextColor}
              onChange={(e) => handleColorChange('tracking-input-text', e.target.value)}/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-8">
                                    <div class="card  p-5 text-center" style={{background:trackingBgColor}}>
                                      <div class="card-body">
                                      <h6 class="mb-3">Tracking Pop-Up Preview</h6>
                                        <div style={{background: "#fff",
                                        padding: "20px",
                                        textAlign: "center"}}>
                                            <h5 style={{fontSize: "18px",
                                            marginBottom: "14px",
                                            color: trackingtitleColor}}>Track Your Order</h5>
                                            <input type="text" class="" id="" placeholder="Enter AWB Number" style={{marginBottom: "20px",
                                            width: "100%",
                                            borderRadius: "20px",
                                            border: "1px solid #d5d5d5",
                                            padding: "9px",
                                            color: trackinginputtextColor,
                                            background: trackinginputboxColor}}/>
                                            <div>
                                                <a href="#" class="" style={{background: trackingbuttonBgColor,
                                                color: trackingbuttontextColor,
                                                padding: "10px 20px",
                                                borderRadius: "20px",
                                                fontSize: "16px",
                                                textDecoration:"none"}}>Submit</a>
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
                

                <div class="row mt-4 mb-3">
                    <div class="col-12 col-md-3">
                    <h5>Webpage Integration Settings</h5>
                    <p>Copy and paste below generated script code on your web page to display the button</p>
                    </div>
                    <div class="col-12 col-md-9">
                        <div class="card border-0 shadow-sm mb-3">
                          <div class="card-body">
                            <h6 class="pb-4">Script Code Widget</h6>
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <h6>Button Code</h6>
                                    <div class="border p-2 code_box" id="buttonCode">
                                        <code>
                                        {generateButtonCode()} 
                                        </code>
                                    </div>
                                  
         
                                    <div class="mt-3">
                                        <a   className={`btn btn-primary btn-sm ${copied ? 'disabled' : ''}`}
          onClick={copyButtonCode}>Copy Code</a>
           {copied ? 'Code Copied!' : ""}
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <h6>Tracking Pop-Up Code</h6>
                                    <div class="border p-2 code_box" id="trackingCode">
                                        <code>
                                        {generateTrackingCode()} 
                                        </code>
                                    </div>
                                    <div class="mt-3">
                                        <a  className={`btn btn-primary btn-sm ${copiedtracking ? 'disabled' : ''}`}
          onClick={copyTrackingCode}>Copy Code</a>
           {copiedtracking ? 'Code Copied!' : ""}
                                    </div>
                                </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>


                {/* <div class="row mt-4 mb-3">
                    <div class="col-12 col-md-3">
                    </div>
                    <div class="col-12 col-md">
                        <div class="pb-4">
                            <a href="#" class="btn btn-primary">Save Changes</a>
                        </div>
                    </div>
                </div> */}
            </div>

            </section>
            </div>
            <Footer/>
    </div>
  )
}

export default Aftership_tracking_script
