import React, { useState, createRef, useEffect } from "react";
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import config from "../../config"


import ReactLoading from 'react-loading';
import { useNetworkState } from 'react-use';
import { Barcode, Boxes, Calculator, Code, Cpu, FileText, Home, LocateFixed, PackageX, Receipt, RotateCcw, ShoppingBag, Store, Truck, User, Warehouse, Weight } from "lucide-react";


const Left_menu = ({ isSidebarOpen, toggleSidebar , props }) => {
    let navigate = useNavigate()
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
  let userDetail = localStorage.getItem('ship_rocket_user');
  const[balancestate,setbalanceState]= React.useState({total_balance:[]})
  // console.log("userDetail config====", config.UserId);
  const SUPPORT = config.SUPPORT
  
  const [userState, setUserState] = useState({ full_name: "" });
 
  const[arrow,setarrow] = React.useState(0)
 
  const [submenuVisible, setSubmenuVisible] = useState(false);
  const [submenushipmentVisible, setSubmenushipmentVisible] = useState(false);
  const [submenuinventoryVisible, setSubmenuinventoryVisible] = useState(false);

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const [isSubMenuOpenbilling, setIsSubMenuOpenbilling] = useState(false);
  const [isSubMenuOpenreport, setIsSubMenuOpenreport] = useState(false);
  const [isSubMenuOpensetting, setIsSubMenuOpensetting] = useState(false);
  // const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  // const toggleSidebar = () => {
  //     setIsSidebarOpen(!isSidebarOpen);
  // };


    // Function to toggle sub-menu
    const toggleSubMenu = () => {
        setIsSubMenuOpen(!isSubMenuOpen);
    };
    const toggleSubMenubilling = () => {
      setIsSubMenuOpenbilling(!isSubMenuOpenbilling);
  };
  const toggleSubMenureport = () => {
    setIsSubMenuOpenreport(!isSubMenuOpenreport);
};
const toggleSubMenusetting = () => {
  setIsSubMenuOpensetting(!isSubMenuOpensetting);
};
    // Function to navigate to create order page
    const navigateToCreateOrder = () => {
        navigate('/customer/create_order_1?value=0');
    };

  const handleSubMenuClick = () => {
    setSubmenuVisible(!submenuVisible);
  };
  const handleshipmentSubMenuClick = () => {
    setSubmenushipmentVisible(!submenushipmentVisible);
  };
  const handleinventorySubMenuClick = () => {
    setSubmenuinventoryVisible(!submenuinventoryVisible);
  };
  
  let userData = {};
  if (userDetail != null) {
    userData = JSON.parse(userDetail)
  }
  console.log("userData == " , userData)
  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000
  })

  React.useEffect(()=>{
    get_wallet_balance()
    // dimension_detail()
    // user()

  },[])
    const logOutButton = () =>{
        // console.log("userDetail ===>   ");
      
        let full_api = config.apiUrl + `/user/user_logout`;
          let sendData = {customer_id:userData.customer_id};
          // console.log("bb", sendData);
          axios.post(full_api, sendData, { headers: config.headers }).then((res) => {
              // console.log("res ", res);
              if (res.data.status==true) {
                localStorage.removeItem('ship_rocket_user');
                userDetail = null;
                setUserState({full_name:""})
                navigate('/customer/login')
              }else {
                Toast.fire({
                  background:"rgb(231, 91, 75)",
                  type: 'unsuccess',
                  title: res.data.message ,
                  color:"white"
                });
                 
              }
      
          }).catch((e) => {
      
      
          });
        
      
      }
      const get_wallet_balance=()=>{
        let dataToSend = {customer_id : userData.customer_id};
       
         // console.log("datatoSend",dataToSend)
         let url = config.apiUrl + '/user/get_customer_wallet';
         axios.post(url, dataToSend, { headers: config.headers })
             .then((res) => {
               // console.log("responseJson => ", res);
               if(res.data.status==true){
           setbalanceState({total_balance:res.data.find_customer_wallet})
        }
       else{
           const Toast = Swal.mixin({
               toast: true,
               position: 'bottom-end',
               showConfirmButton: false,
               timer: 3000
             })
             Toast.fire({
               background:"rgb(231, 91, 75)",
               type: 'Unsuccess',
               title: "Something Went Wrong !",
               color:"white"
             });
       } })
           
             .catch((error) => {
               const Toast = Swal.mixin({
                   toast: true,
                   position: 'bottom-end',
                   showConfirmButton: false,
                   timer: 3000
                 })
                 Toast.fire({
                   background:"rgb(231, 91, 75)",
                   type: 'Unsuccess',
                   title: "Something Went Wrong !",
                   color:"white"
                 });
                 // console.log(error);
             });    
       
       }
    // let value  = props.value
    // console.log("value ====" , value)

    const arrowset =()=>{
        if(arrow==0){
            setarrow(1)
        }
        else{
            setarrow(0)
        }
    }
    const redirectToShipmentsPage = (statuses) => {
      const statusParam = statuses.join('%2C'); // Join status array with comma and encode
      window.location.href = `/customer/shipments?status=${statusParam}`;
  };
    const handleDeliveredShipmentsClick = () => {
      // Redirect to Shipments page with only status 5 included
      redirectToShipmentsPage([5]);
  };
  const handleBookedShipmentsClick = () => {
    // Redirect to Shipments page with all statuses included
    redirectToShipmentsPage([15]);
};
const handleNdrShipmentsClick = () => {
  // Redirect to Shipments page with all statuses included
  redirectToShipmentsPage([8]);
};
const handleRtoShipmentsClick = () => {
  // Redirect to Shipments page with all statuses included
  redirectToShipmentsPage([10]);
};
const handleScheduledShipmentsClick = () => {
  // Redirect to Shipments page with all statuses included
  redirectToShipmentsPage([9]);
};
    
  return (
    // <div class={arrow==0?"sidebar":"sidebar close"}>
    //   <nav >
    //     <header>
    //         <div class="image-text">
    //             <span class="image">
    //                 <img src="../../img/logo.png" alt=""/>
    //             </span>

    //             <div class="text logo-text">
    //                 <span class="name">Shiport.in</span>
    //                 <span class="profession">{userData.full_name}</span>
    //             </div>
    //         </div>

    //         <i class='bx bx-chevron-right toggle' onClick={(e)=>arrowset(e)}></i>
    //     </header>

    //     <div class="menu-bar">
    //         <div class="menu">

    //             {/* <!-- <li class="search-box">
    //                 <i class='bx bx-search icon'></i>
    //                 <input type="text" placeholder="Search...">
    //             </li> --> */}

    //             <ul class="menu-links">
    //                 <li class="nav-link">
    //                     <a href="/" class={value==1?"active":""}>
    //                         <i class='bx bx-home-alt icon' ></i>
    //                         <span class="text nav-text">Dashboard</span>
    //                     </a>
    //                 </li>

    //                 <li class="nav-link">
    //                     <a href="/customer/orders" class={value==2?"active":""}>
    //                         <i class='bx bx-cart icon' ></i>
    //                         <span class="text nav-text">Orders</span>
    //                     </a>
    //                 </li>

    //                 <li class="nav-link">
    //                     <a href="/customer/shipments" class={value==3?"active":""}>
    //                         <i class='bx bx-package icon'></i>
    //                         <span class="text nav-text">Shipments</span>
    //                     </a>
    //                 </li>

    //                 {/* <li class="nav-link">
    //                     <a href="coming_soon.php">
    //                         <i class='bx bx-task-x icon' ></i>
    //                         <span class="text nav-text">NDR</span>
    //                     </a>
    //                 </li>

    //                 <li class="nav-link">
    //                     <a href="coming_soon.php">
    //                         <i class='bx bx-reset icon' ></i>
    //                         <span class="text nav-text">Returns</span>
    //                     </a>
    //                 </li> */}

    //                 <li class="nav-link">
    //                     <a href="/customer/warehouse" class={value==6?"active":""}>
    //                         <i class='bx bx-building-house icon' ></i>
    //                         <span class="text nav-text">Warehouse</span>
    //                     </a>
    //                 </li>


    //                 <li class="nav-link">
    //                     <a href="/customer/billing_shipping_charges" className={value==7 || value==8 || value ==9?"active":""}>
    //                         <i class='bx bx-file icon' ></i>
    //                         <span class="text nav-text">Billings</span>
    //                     </a>
    //                 </li>

    //                 <li class="nav-link">
    //                     <a href="/customer/tools" className={value ==10?"active":""}>
    //                         <i class='bx bx-candles icon' ></i>
    //                         <span class="text nav-text">Tools</span>
    //                     </a>
    //                 </li>

    //                 <li class="nav-link">
    //                     <a href="/customer/settings" className={value ==11?"active":""}>
    //                         <i class='bx bx-cog icon' ></i>
    //                         <span class="text nav-text">Settings</span>
    //                     </a>
    //                 </li>
    //                 {SUPPORT?
    //                 <li class="nav-link">
    //                     <a href="/customer/tickets" className={value ==12?"active":""}>
    //                         <i class='bx bx-cog icon' ></i>
    //                         <span class="text nav-text">Support</span>
    //                     </a>
    //                 </li>
    //                 :""}
    //                    <li class="nav-link">
    //                     <a href="/customer/reports" className={value ==13?"active":""}>
    //                         <i class='bx bx-cog icon' ></i>
    //                         <span class="text nav-text">Reports</span>
    //                     </a>
    //                 </li>

    //             </ul>
    //         </div>

    //         <div class="bottom-content">
    //             <li class="">
    //                 <a onClick={() => logOutButton()}>
    //                     <i class='bx bx-log-out icon' ></i>
    //                     <span class="text nav-text">Logout</span>
    //                 </a>
    //             </li>

    //             {/* <!-- <li class="mode">
    //                 <div class="sun-moon">
    //                     <i class='bx bx-moon icon moon'></i>
    //                     <i class='bx bx-sun icon sun'></i>
    //                 </div>
    //                 <span class="mode-text text">Dark mode</span>

    //                 <div class="toggle-switch">
    //                     <span class="switch"></span>
    //                 </div>
    //             </li> -->
    //              */}
    //         </div>
    //     </div>

    // </nav>
    // </div>
//     <div className={arrow==0?"wrapper":"wrapper sidebar-closed"}>
//         {/* <!-- Sidebar starts --> */}
// <div class="">
//         <nav class={arrow==0?"sidebar locked":"sidebar hoverable close"}>
//             <div class="logo_items flex">
//                 <span class="nav_image">
//                 <img src="../img/logo_sky.png" alt="logo_img" />
//                 </span>
//                 <span class="logo_name">SkyShip</span>
//                 <i class={arrow==0?"bx bx-lock-alt":"bx bx-lock-open-alt"} onClick={(e)=>arrowset(e)} id="lock-icon" title="Unlock Sidebar"></i>
//                 <i class="bx bx-x" id="sidebar-close"></i>
//             </div>
            
//             <div class="menu_container">
//                 <div class="side_nav_top">
//                     <div class="row">
//                         <div class="col-12 col-md-2">
//                         {/* <i data-lucide="user"></i> */}
//                         <User/>
//                         </div>
//                         <div class="col-12 col-md customer_kyc">
                       
//                             <h6>{userData.full_name}  </h6>
//                             <span class="text-primary" data-bs-toggle="tooltip" title="Wallet Balance">₹{balancestate.total_balance.map((sub)=>(
//                        parseFloat(sub.wallet_balance).toFixed(2)))}</span>
// {/* <!--                             
//                             <span class="badge_unverified">Unverified</span>
//                             <span class="badge_verified">Verified</span>  --> */}
//                         </div>
//                         <div class="col-12 col-md-2">
//                             <div class="custom-dropdown ">
//                                 <i class="material-icons-outlined">more_vert</i>

//                                 <ul class="dropdown-menu shadow-sm ">
//                                     {/* <li><a class="dropdown-item" href="#">My Profile</a></li> */}
//                                     <li><a class="dropdown-item" href="/customer/customer_kyc">Customer KYC 
//                                     </a></li>
//                                     <li><hr class="dropdown-divider" /></li>
//                                     <li><a class="dropdown-item" onClick={() => logOutButton()}>Logout</a></li>
//                                 </ul>
//                             </div>
//                         </div>
//                     </div>
//                 </div>

//                 <div class="menu_items">
//                 <ul class="menu_item">
//                     <div class="menu_title flex">
//                         <span class="title">Dashboard</span>
//                         <span class="line"></span>
//                     </div>
//                     <li class="item">
//                         <a href="/customer/dashboard" class= {value ==1?"link flex active":"link flex"}>
//                         {/* <i data-lucide="home"></i> */}
//                         <Home/>
//                         <span>Dashboard</span>
//                         </a>
//                     </li>
//                 </ul>
//                 <ul class="menu_item">
//                     <div class="menu_title flex">
//                         <span class="title">Shipments</span>
//                         <span class="line"></span>
//                     </div>
//                     <li className="item">
//       <a  className={value ==2?"link flex active":"link flex"} onClick={handleSubMenuClick}>
//         <ShoppingBag />
//         <span>Orders</span>
//         <i className="bx bx-chevron-down list_dropdown" id="overview-caret"></i>
//       </a>
//       {/* Sub-menu for Overview */}
//       {submenuVisible && (
//         <ul className="">
//           <li className="item-level-1">
//             <a href="/customer/orders" className="link flex">
//               <span>All Orders</span>
//             </a>
//           </li>
//           <li className="item-level-1">
//             <a onClick={()=>navigate({
//              pathname: '/customer/create_order_1',
//              search: `value=0`,
//            })} style={{cursor:"pointer"}} className="link flex">
//               <span>Create new order</span>
//             </a>
           
//              {/* <a href="/customer/create_order_new" style={{cursor:"pointer"}} className="link flex">
//               <span>Create new order</span>
//             </a> */}
//           </li>
//           <li className="item-level-1">
//             <a href="/customer/order_import" className="link flex">
//               <span>Import bulk orders</span>
//             </a>
//           </li>
//         </ul>
//         )} 
//     </li>
//                     <li class="item">
//                         <a class={value ==3?"link flex active":"link flex"} onClick={handleshipmentSubMenuClick}>
//                         {/* <i data-lucide="boxes"></i> */}
//                         <Boxes/>
//                         <span>Shipments</span>
//                         <i class="bx bx-chevron-down list_dropdown" id="overview-caret"></i>
//                         </a>
//                         {/* <!-- Sub-menu for Overview --> */}
//                         {submenushipmentVisible&&(
//                         <ul class="">
//                             <li class="item-level-1">
//                                 <a href="/customer/shipments"  class="link flex">
//                                 <span>All Shipments</span>
//                                 </a>
//                             </li>

//                             <li class="item-level-1">
//                                 <a href="/customer/booked_shipments" class="link flex">
//                                 <span>Booked Shipments</span>
//                                 </a>
//                             </li>
//                             <li class="item-level-1">
//                                 <a href="/customer/delivered_shipments" class="link flex">
//                                 <span>Delivered Shipments</span>
//                                 </a>
//                             </li>
//                         </ul>
//                         )}
//                     </li>
//                     <li class="item">
//                         <a href="/customer/ndr_shipments" class={value ==32?"link flex active":"link flex"}>
//                         <PackageX/>
//                             <span>NDR Shipments</span>
//                         </a>
//                     </li>
//                     <li class="item">
//                         <a href="/customer/rto_shipments" class={value ==33?"link flex active":"link flex"}>
//                        <RotateCcw/>
//                             <span>RTO Shipments</span>
//                         </a>
//                     </li>
//                 </ul>

//                 <ul class="menu_item">
//                     <div class="menu_title flex">
//                         <span class="title">Operations</span>
//                         <span class="line"></span>
//                     </div>
//                     <li class="item">
//                         <a href="/customer/scheduled_shipments" class={value ==34?"link flex active":"link flex"}>
//                           <Truck/>
//                             <span>Scheduled Shipments</span>
//                         </a>
//                     </li>
//                     <li class="item">
//                         <a href="/customer/weight_discrepancies_list" class={value ==35?"link flex active":"link flex"}>
//                            <Weight/>
//                             <span>Weight Discrepancy</span>
//                         </a>
//                     </li>
//                     <li class="item">
//                     <a href="/customer/warehouse" class={value ==6?"link flex active":"link flex"}>
//                         {/* <i data-lucide="warehouse"></i> */}
//                         <Warehouse/>
//                         <span>Warehouse</span>
//                     </a>
//                     </li>
//                 </ul>
                
//                 <ul class="menu_item">
//                     <div class="menu_title flex">
//                     <span class="title">Billing</span>
//                     <span class="line"></span>
//                     </div>
//                     <li class="item">
//                     <a href="/customer/billing_shipping_charges" class={value ==7?"link flex active":"link flex"}>
//                     {/* <i data-lucide="receipt"></i> */}
//                     <Receipt/>
//                         <span>Billing</span>
//                     </a>
//                     </li>
//                 </ul>


//                 <ul class="menu_item">
//                     <div class="menu_title flex">
//                     <span class="title">Tools</span>
//                     <span class="line"></span>
//                     </div>
//                     <li class="item">
//                     <a href="/customer/rate_calculator" class={value ==10?"link flex active":"link flex"}>
//                     {/* <i data-lucide="calculator"></i> */}
//                     <Calculator/>
//                         <span>Rate Calculator</span>
//                     </a>
//                     </li>
//                     {/* <li class="item">
//                     <a href="#" class="link flex">
                  
//                     <Cpu/>
//                         <span>Order Allocation Engine</span>
//                     </a>
//                     </li> */}
//                     {/* <li class="item">
//                     <a href="/customer/after_ship" class={value ==20?"link flex active":"link flex"}>
                  
//                     <LocateFixed/>
//                         <span>AfterShip</span>
//                     </a>
//                     </li> */}
//                     <li class="item">
//                     <a href="/customer/reports" class={value ==13?"link flex active":"link flex"}>
//                     {/* <i data-lucide="file-text"></i> */}
//                     <FileText/>
//                         <span>Reports</span>
//                     </a>
//                     </li>
//                 </ul>
        
//                 <ul class="menu_item">
//                     <div class="menu_title flex">
//                     <span class="title">Setting</span>
//                     <span class="line"></span>
//                     </div>
//                     <li class="item">
//                     <a href="/customer/channel_list" class={value ==11?"link flex active":"link flex"}>
//                     {/* <i data-lucide="store"></i> */}
//                     <Store/>
//                         <span>Channel Integration</span>
//                     </a>
//                     </li>
//                     {/* <li class="item">
//                     <a href="/customer/label_settings" class={value ==22?"link flex active":"link flex"}>
                   
//                     <Barcode/>
//                         <span>Lable Settings</span>
//                     </a>
//                     </li> */}
//                     {/* <li class="item">
//                     <a href="#" class="link flex">
                 
//                     <Code/>
//                         <span>Developer APIs</span>
//                     </a>
//                     </li> */}
//                 </ul>
//                 </div>
        
//                 {/* <!-- <div class="sidebar_profile flex">
//                     <span class="nav_image">
//                         <img src="images/profile.jpg" alt="logo_img" />
//                     </span>
//                     <div class="data_text">
//                         <span class="name">David Oliva</span>
//                         <span class="email">david@gmail.com</span>
//                     </div>
//                 </div> --> */}
//             </div>
//         </nav>
//       </div>
//       {/* <!-- Sidebar End --> */}
//     </div>
<div>
<div className={`sidebar ${isSidebarOpen ? '' : 'close'}`}>
          <div class="logo-details">
            <div class="head_icon">
                <i class="bx bx-menu" onClick={toggleSidebar}></i>
            </div>
            <span class="logo_name">Connectingmiles</span>
          </div>
          <ul class="nav-links">
            <li>
              <div class="iocn-link">
                <a href="/customer/new_dashboard" >
                  <i class="bx bx-grid-alt"></i>
                  <span class="link_name">Dashboard</span>
                </a>
                {/* <i class="bx bxs-chevron-down arrow"></i> */}
              </div>
              <ul class="sub-menu">
                <li><a class="link_name" href="/customer/new_dashboard">Dashboard</a></li>
                {/* <li><a href="index.php">Dashboard</a></li>
                <li><a href="daily_shipment_summary.php">Daily Shipment Summary</a></li> */}
              </ul>
            </li>
            {userData.user_type==1?
            <li className={isSubMenuOpen ? 'showMenu' : ''}>
            <div className="iocn-link" onClick={toggleSubMenu}>
                <a >
                    <i className="bx bx-cart"></i>
                    <span className="link_name">Orders</span>
                </a>
                <i className={`bx bxs-chevron-down arrow ${isSubMenuOpen ? 'open' : ''}`} onClick={toggleSubMenu}></i>
            </div>
            
           
                <ul className="sub-menu ">
                    <li ><a className="link_name" href="/customer/orders">Orders</a></li>
                    <li><a href="/customer/orders">Orders</a></li>
                    {/* <li><a href="/customer/b2b_orders">B2B Orders</a></li> */}

                    <li><a onClick={navigateToCreateOrder} style={{cursor: "pointer"}}>Create new order</a></li>
                    <li><a href="/customer/order_import">Import bulk order</a></li>
                </ul>
           
        </li>
        :userData.user_type==2 && userData.previlage.orders!=undefined && userData.previlage.orders!=false?
        <li className={isSubMenuOpen ? 'showMenu' : ''}>
        <div className="iocn-link" onClick={toggleSubMenu}>
            <a >
                <i className="bx bx-cart"></i>
                <span className="link_name">Orders</span>
            </a>
            <i className={`bx bxs-chevron-down arrow ${isSubMenuOpen ? 'open' : ''}`} onClick={toggleSubMenu}></i>
        </div>
        
       
            <ul className="sub-menu ">
                <li ><a className="link_name" href="/customer/orders">Orders</a></li>
                <li><a href="/customer/orders">Orders</a></li>
                {/* <li><a href="/customer/b2b_orders">B2B Orders</a></li> */}

                <li><a onClick={navigateToCreateOrder} style={{cursor: "pointer"}}>Create new order</a></li>
                <li><a href="/customer/order_import">Import bulk order</a></li>
            </ul>
       
    </li>:""}

    {userData.user_type==1?
            <li className={submenushipmentVisible ? 'showMenu' : ''}>
              <div class="iocn-link" onClick={handleshipmentSubMenuClick}>
                <a href="#">
                  <i class="bx bx-package"></i>
                  <span class="link_name">Shipments</span>
                </a>
                <i className={`bx bxs-chevron-down arrow ${submenushipmentVisible ? 'open' : ''}`} onClick={handleshipmentSubMenuClick}></i>
              </div>
             
              <ul class="sub-menu">
                <li><a class="link_name" href="/customer/shipments">Shipments</a></li>
                <li><a href="/customer/shipments">All Shipments</a></li>
                <li><a onClick={handleBookedShipmentsClick}>Booked Shipments</a></li>
                <li><a onClick={handleDeliveredShipmentsClick}>Delivered Shipments</a></li>
                <li><a onClick={handleNdrShipmentsClick}>NDR Shipments</a></li>
                <li><a  onClick={handleRtoShipmentsClick}>RTO Shipments</a></li>
                <li><a  onClick={handleScheduledShipmentsClick}>Scheduled Shipments</a></li>
              </ul>
             
            </li>
           : userData.user_type==2 && userData.previlage.shipments!=undefined && userData.previlage.shipments!=false?
           <li className={submenushipmentVisible ? 'showMenu' : ''}>
              <div class="iocn-link" onClick={handleshipmentSubMenuClick}>
                <a href="#">
                  <i class="bx bx-package"></i>
                  <span class="link_name">Shipments</span>
                </a>
                <i className={`bx bxs-chevron-down arrow ${submenushipmentVisible ? 'open' : ''}`} onClick={handleshipmentSubMenuClick}></i>
              </div>
             
              <ul class="sub-menu">
                <li><a class="link_name" href="/customer/shipments">Shipments</a></li>
                <li><a href="/customer/shipments">All Shipments</a></li>
                <li><a onClick={handleBookedShipmentsClick}>Booked Shipments</a></li>
                <li><a onClick={handleDeliveredShipmentsClick}>Delivered Shipments</a></li>
                <li><a onClick={handleNdrShipmentsClick}>NDR Shipments</a></li>
                <li><a  onClick={handleRtoShipmentsClick}>RTO Shipments</a></li>
                <li><a  onClick={handleScheduledShipmentsClick}>Scheduled Shipments</a></li>
              </ul>
             
            </li>
           :""}
            
            {userData.user_type==1?
            <li>
              <a href="/customer/warehouse">
                <i class="bx bx-building-house"></i>
                <span class="link_name">Warehouse</span>
              </a>
              <ul class="sub-menu blank" >
                <li><a class="link_name" href="/customer/warehouse">Warehouse</a></li>
              </ul>
            </li>
            :userData.user_type==2 && userData.previlage.warehouse!=undefined && userData.previlage.warehouse!=false?
            <li>
            <a href="/customer/warehouse">
              <i class="bx bx-building-house"></i>
              <span class="link_name">Warehouse</span>
            </a>
            <ul class="sub-menu blank" >
              <li><a class="link_name" href="/customer/warehouse">Warehouse</a></li>
            </ul>
          </li>
            :""}

            {/* <li className={submenuinventoryVisible ? 'showMenu' : ''}>
              <div class="iocn-link" onClick={handleinventorySubMenuClick}>
                <a href="#">
                  <i class="bx bx-store"></i>
                  <span class="link_name">Inventory Management</span>
                </a>
                <i className={`bx bxs-chevron-down arrow ${submenuinventoryVisible ? 'open' : ''}`} onClick={handleinventorySubMenuClick}></i>
              </div>
              <ul class="sub-menu">
                <li><a class="link_name" href="">Inventory Management</a></li>
                <li><a href="/customer/warehouse_inventory">Inventory Management</a></li>
                <li><a href="/customer/product_list">Product Management</a></li>
                <li><a href="/customer/stock_report">Stock Report</a></li>

                
              </ul>
            </li> */}

{userData.user_type==1?
            <li>
              <a href="/customer/weight_discrepancies_list">
                <i class="bx bx-scatter-chart"></i>
                <span class="link_name">Weight Discrepancy</span>
              </a>
              <ul class="sub-menu blank" >
                <li><a class="link_name" href="/customer/weight_discrepancies_list">Weight Discrepancy</a></li>
              </ul>
            </li>
            :userData.user_type==2 && userData.previlage.weight_discrepancy!=undefined && userData.previlage.weight_discrepancy!=false?
<li>
              <a href="/customer/weight_discrepancies_list">
                <i class="bx bx-scatter-chart"></i>
                <span class="link_name">Weight Discrepancy</span>
              </a>
              <ul class="sub-menu blank" >
                <li><a class="link_name" href="/customer/weight_discrepancies_list">Weight Discrepancy</a></li>
              </ul>
            </li>
            :""}

{userData.user_type==1?
            <li className={submenuVisible ? 'showMenu' : ''}>
              <div class="iocn-link">
                <a onClick={handleSubMenuClick}>
                  <i class="bx bx-sushi"></i>
                  <span class="link_name">Tools</span>
                </a>
                <i className={`bx bxs-chevron-down arrow ${submenuVisible ? 'open' : ''}`} onClick={handleSubMenuClick}></i>
              </div>
            
              <ul class="sub-menu">
                {/* <li><a class="link_name" href="orders.php">Tools</a></li> */}
                <li><a href="/customer/rate_calculator">Rate Calculator</a></li>
                {/* <li><a href="#">Order Allocation Engine</a></li> */}
                <li><a href="/customer/aftership">AfterShip</a></li>
                {/* <li><a href="/customer/boxes">Boxes</a></li> */}

              </ul>
             
            </li>
 :userData.user_type==2 && userData.previlage.tools!=undefined && userData.previlage.tools!=false?
 <li className={submenuVisible ? 'showMenu' : ''}>
 <div class="iocn-link">
   <a onClick={handleSubMenuClick}>
     <i class="bx bx-sushi"></i>
     <span class="link_name">Tools</span>
   </a>
   <i className={`bx bxs-chevron-down arrow ${submenuVisible ? 'open' : ''}`} onClick={handleSubMenuClick}></i>
 </div>

 <ul class="sub-menu">
   {/* <li><a class="link_name" href="orders.php">Tools</a></li> */}
   <li><a href="/customer/rate_calculator">Rate Calculator</a></li>
   {/* <li><a href="#">Order Allocation Engine</a></li> */}
   <li><a href="/customer/aftership">AfterShip</a></li>
   {/* <li><a href="/customer/boxes">Boxes</a></li> */}

 </ul>

</li>:""}

{userData.user_type==1?
            <li className={isSubMenuOpenbilling ? 'showMenu' : ''}>
              <div class="iocn-link">
                <a onClick={toggleSubMenubilling}>
                  <i class="bx bx-receipt"></i>
                  <span class="link_name">Billing</span>
                </a>
                <i className={`bx bxs-chevron-down arrow ${isSubMenuOpenbilling ? 'open' : ''}`} onClick={toggleSubMenubilling}></i>
              </div>
           
              <ul class="sub-menu">
                {/* <li><a class="link_name" href="#">Billing</a></li> */}
                <li><a href="/customer/billing_shipping_charges">Shipping Charges</a></li>
                <li><a href="/customer/billing_cod_remittance">COD Remittance</a></li>
                <li><a href="/customer/billing_wallet">Wallet History</a></li>
              </ul>
             
            </li>
            :userData.user_type==2 && userData.previlage.billing!=undefined && userData.previlage.billing!=false?
            <li className={isSubMenuOpenbilling ? 'showMenu' : ''}>
              <div class="iocn-link">
                <a onClick={toggleSubMenubilling}>
                  <i class="bx bx-receipt"></i>
                  <span class="link_name">Billing</span>
                </a>
                <i className={`bx bxs-chevron-down arrow ${isSubMenuOpenbilling ? 'open' : ''}`} onClick={toggleSubMenubilling}></i>
              </div>
           
              <ul class="sub-menu">
                {/* <li><a class="link_name" href="#">Billing</a></li> */}
                <li><a href="/customer/billing_shipping_charges">Shipping Charges</a></li>
                <li><a href="/customer/billing_cod_remittance">COD Remittance</a></li>
                <li><a href="/customer/billing_wallet">Wallet History</a></li>
              </ul>
             
            </li> :""}

            {userData.user_type==1?
            <li>
              <a href="/customer/tickets">
                <i class="bx bx-support"></i>
                <span class="link_name">Support</span>
              </a>
              <ul class="sub-menu blank" >
                <li><a class="link_name" href="/customer/tickets">Support</a></li>
                {/* <li><a class="link_name" href="Reports.php">All Tickets</a></li> */}
              </ul>
            </li>

:userData.user_type==2 && userData.previlage.support!=undefined && userData.previlage.support!=false?
<li>
<a href="/customer/tickets">
  <i class="bx bx-support"></i>
  <span class="link_name">Support</span>
</a>
<ul class="sub-menu blank" >
  <li><a class="link_name" href="/customer/tickets">Support</a></li>
  {/* <li><a class="link_name" href="Reports.php">All Tickets</a></li> */}
</ul>
</li>
:""}

    {userData.user_type==1?
            <li  className={isSubMenuOpenreport ? 'showMenu' : ''}>
              <div class="iocn-link">
                <a onClick={toggleSubMenureport}>
                  <i class="bx bx-receipt"></i>
                  <span class="link_name">Reports</span>
                </a>
                <i className={`bx bxs-chevron-down arrow ${isSubMenuOpenbilling ? 'open' : ''}`} onClick={toggleSubMenureport}></i>
              </div>
             
              <ul class="sub-menu" >
                {/* <li><a class="link_name" href="#">Reports</a></li> */}
                <li><a href="/customer/reports">Shipment Reports</a></li>
                <li><a  href="/customer/invoices">Invoices</a></li>
              </ul>
            
            </li>
           :userData.user_type==2 && userData.previlage.reports!=undefined && userData.previlage.reports!=false?
           <li  className={isSubMenuOpenreport ? 'showMenu' : ''}>
           <div class="iocn-link">
             <a onClick={toggleSubMenureport}>
               <i class="bx bx-receipt"></i>
               <span class="link_name">Reports</span>
             </a>
             <i className={`bx bxs-chevron-down arrow ${isSubMenuOpenbilling ? 'open' : ''}`} onClick={toggleSubMenureport}></i>
           </div>
          
           <ul class="sub-menu" >
             {/* <li><a class="link_name" href="#">Reports</a></li> */}
             <li><a href="/customer/reports">Shipment Reports</a></li>
             <li><a  href="/customer/invoices">Invoices</a></li>
           </ul>
         
         </li>:""}

         {userData.user_type==1?
            <li className={isSubMenuOpensetting ? 'showMenu' : ''}>
              <div class="iocn-link">
                <a onClick={toggleSubMenusetting}>
                  <i class="bx bx-cog"></i>
                  <span class="link_name">Settings</span>
                </a>
                <i className={`bx bxs-chevron-down arrow ${isSubMenuOpensetting ? 'open' : ''}`} onClick={toggleSubMenusetting}></i>
              </div>
            
              <ul class="sub-menu" >
                <li><a class="link_name" >Settings</a></li>
                {/* <li><a class="" href="/customer/edit_profile">Profile</a></li> */}
                {/* <li><a class="" href="/customer/staff">Staff</a></li> */}
                <li><a href="/customer/channel_list">Channel Integration</a></li>
                <li><a href="/customer/label_settings">Lable Settings</a></li>
                <li><a href="/customer/customer_kyc">Customer KYC</a></li>
                <li><a href="/customer/customer_bank">Bank Information</a></li>
                {/* <li><a href=".php">Developer APIs</a></li> */}
              </ul>
            
            </li>

 :userData.user_type==2 && userData.previlage.settings!=undefined && userData.previlage.settings!=false?
 <li className={isSubMenuOpensetting ? 'showMenu' : ''}>
 <div class="iocn-link">
   <a onClick={toggleSubMenusetting}>
     <i class="bx bx-cog"></i>
     <span class="link_name">Settings</span>
   </a>
   <i className={`bx bxs-chevron-down arrow ${isSubMenuOpensetting ? 'open' : ''}`} onClick={toggleSubMenusetting}></i>
 </div>

 <ul class="sub-menu" >
   <li><a class="link_name" >Settings</a></li>
   {/* <li><a class="" href="/customer/edit_profile">Profile</a></li> */}
   {/* <li><a class="" href="/customer/staff">Staff</a></li> */}
   <li><a href="/customer/channel_list">Channel Integration</a></li>
   <li><a href="/customer/label_settings">Lable Settings</a></li>
   <li><a href="/customer/customer_kyc">Customer KYC</a></li>
   <li><a href="/customer/customer_bank">Bank Information</a></li>
   {/* <li><a href=".php">Developer APIs</a></li> */}
 </ul>

</li>
:""}

            <li>
            <div class="profile-details">
                <div class="profile-content">
                <img src="/img/profile.png" alt="profileImg"/>
                </div>
                <div class="name-job">
                <div class="profile_name">{userData.full_name}</div>
                <div class="job">{userData.company_name?userData.company_name:""} </div>
                </div>
                <i class="bx bx-log-out" onClick={() => logOutButton()}></i>
            </div>
        </li>
      </ul>
    </div>
</div>
  )
}

export default Left_menu
