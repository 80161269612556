import React from 'react'
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import config from "../config"
import axios from 'axios';
import Select from 'react-select';
import { useRef } from 'react';

import Header_nav from './Header_nav'
import Shipping_form from './Shipping_form';
import { toast } from 'react-toastify';
import { getValue } from '@testing-library/user-event/dist/utils';

const Footer = (props) => {
  let navigate = useNavigate()
  const [popularstate, setpopularState] = React.useState({
    popular_country: [],
  isLoading:true  
  })
  const { value } = props;
  const popular_country = () => {
  
    let full_api =config.apiUrl + '/home/get_popular_country';
    let sendData = {
      popular_country: {},
   
    };
    
  
    axios.post(full_api, sendData, { headers:config.headers}).then(res => {
      setpopularState({ ...popularstate,popular_country: res.data.popular_country ,isLoading:false });
   
      
    })
  }
  React.useEffect(() => {
    popular_country()
  }, [])
  

  return (
    <div>
     <footer>
     <div class="padding-bottom"></div>     
  
     <div class="footer d-md-none d-lg-none d-xl-none d-xxl-none d-sm-block">  
            <div class="no-gutters">
                <div class="col-auto mx-auto">
                    <div class="row no-gutters justify-content-center">
                        <div class="col-auto">
                            <a href="/customer/new_dashboard" class={value==1?"btn btn-link-default active":"btn btn-link-default "}>
                                <i class="material-icons">store_mall_directory</i>
                            </a>
                        </div>
                        <div class="col-auto">
                            <a href="/customer/orders" class={value==2?"btn btn-link-default active":"btn btn-link-default "}>
                                <i class="material-icons">local_mall</i>
                            </a>
                        </div>  
                        <div class="col-auto">
                            <a href="/customer/shipments" class={value==3?"btn btn-link-default active":"btn btn-link-default "}>
                                <i class="material-icons">view_in_ar</i>
                            </a>
                        </div>
                        <div class="col-auto">
                            <a href="/customer/nav_setting" class={value==4?"btn btn-link-default active":"btn btn-link-default "}>
                                <i class="material-icons">settings</i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</footer>
    </div>
  )
}

export default Footer
