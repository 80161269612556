import React from 'react'
import Left_menu from './Left_menu'
import Header from './Header'
import { useNavigate } from 'react-router-dom'
import { useNetworkState } from 'react-use';
import Footer from '../Footer';
const Tools = () => {
    let navigate = useNavigate()
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };
  return (
    <div>
   <Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <div className={`home-section ${isSidebarOpen ? '' : 'sidebar-closed'}`}>
         
          <Header/>
        <section class="mb-3 mt-3 tools">
          <div class="">
            <h3 class="py-3">Tools</h3>
            <div class="row">
                <div class="col-12 col-md-4 mb-3">
                    <a onClick={()=>navigate({
            pathname: '/customer/rate_calculator',
            
          })} style={{textDecoration:"none"}}>
                        <div class="card border-0 shadow-sm">
                            <div class="card-body ">
                                <div class="iconset">
                                    <div class="icon color-2">
                                        <i class="material-icons-outlined">calculate</i>
                                    </div>
                                </div>
                                <h5>Rate Calculator</h5>
                                <small>Calculate Shipping Rates in real time</small>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-md-4 mb-3">
                    <a href="#" class="" style={{textDecoration:"none"}}>
                        <div class="card border-0 shadow-sm">
                            <div class="card-body ">
                                <div className='row'>
                                    <div className='col-md-6'>
                                    <div class="iconset">
                                    <div class="icon color-2">
                                        <i class="material-icons-outlined">attractions</i>
                                    </div>
                                </div>
                                    </div>
                                    <div className='col-md-6 mt-3'>
                                     <h6>Coming Soon</h6>
                                    </div>
                                </div>
                              
                                <h5>Order Allocation Engine</h5>
                                <small>Setup your Order Allocation Engine</small>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-md-4 mb-3">
                    <a href="#" class="" style={{textDecoration:"none"}}>
                        <div class="card border-0 shadow-sm">
                            <div class="card-body ">
                                <div className='row'>
                                  <div className='col-md-6'>
                                  <div class="iconset">
                                    <div class="icon color-2">
                                        <i class="material-icons-outlined">share_location</i>
                                    </div>
                                </div>
                                  </div>
                                  <div className='col-md-6 mt-3'>
                                    <h6>Coming Soon</h6>
                                  </div>
                                </div>
                             
                                <h5>AfterShip</h5>
                                <small>Customize your store tracking page</small>
                            </div>
                        </div>
                    </a>
                </div>
            </div>      
          </div>
        </section>
        
 </div>

<Footer/>
    {/* <?php include 'footer.php';?> */}
    </div>
  )
}

export default Tools
