import React, { useEffect, useState } from 'react'
import Left_menu from './Left_menu'
import Header from './Header'
import Create_order_nav from './Create_order_nav'
import axios from 'axios'
import queryString from 'query-string';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
// import './customer_style.css';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';


import Swal from 'sweetalert2'

import ReactLoading from 'react-loading';
import config from "../../config"
import { useNetworkState } from 'react-use';
import Footer from '../Footer'

const Wallet_payment_success = () => {
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
    let flag = true;
    let { transactionId } = useParams();
    const [state, setState] = useState({ status: 4 });
    let navigate = useNavigate()
    const location = useLocation();
    // console.log(location);
    // console.log(transactionId);

    useEffect(() => {
        pay_using_phonePay()
    }, [])

    const pay_using_phonePay = () => {
        // console.log("-------")
        if (flag && transactionId) {
            flag = false;
            let full_api = config.apiUrl + `/user/payment_transaction_check`;
            let sendData = { transaction_id: transactionId };

            axios.post(full_api, sendData, { headers: config.headers }).then(res => {
                // console.log("rnmmmmm", res);
                if (res.data.status) {
                    setState(res.data.output[0])
                }

            }).catch((e) => {
                // toast.configure()
                //toast.error("Some thing went wrong")
                // console.log("----error:   ", e);
            })
        }
    }

    const goBackTowallet = () => {
        navigate('/customer/billing_wallet')
    }

    const refreshCall = () => {
        window.location.reload();
    }

    const tryAgainCall = () => {
        navigate('/customer/wallet_recharge')
    }


    return (
        <div>
            {/* <?php include 'meta.php';?> */}
            <Left_menu value={11} />
            {/* <?php include 'left_menu.php';?> */}

            <section class="home ">
                {/* <?php include 'header.php';?>  */}
                <Header />


                <section class="mb-3 mt-3 tools">
                    {/* <div class="container">
                        <h3 class="py-3">Payment {(state.status == 1) ? "Successfull" : (state.status == 2) ? "Pending" : (state.status == 3) ? "Failed" : "Transaction not found."}</h3>
                        <div class="row">
                            <div class="col-12 col-md-4 mb-3">
                                <button class="btn btn-primary btn-block" onClick={goBackTowallet}>Continue</button>
                            </div>
                        </div>
                    </div> */}
                    {(state.status == 1) ?
                        <div class="container mt-5">
                            <div class="alert alert-success text-center">
                                <h1>Payment Successful</h1>
                                <p>Your payment has been successfully processed.</p>
                                <p>Thank you for your purchase!</p>
                                <button class="btn btn-primary btn-block" onClick={goBackTowallet}>Back to Home</button>
                                {/* <a href="index.html" class="btn btn-primary"></a> */}
                            </div>
                        </div>
                        : (state.status == 2) ?
                            <div class="container mt-5">
                                <div class="alert alert-warning text-center">
                                    <h1>Payment Pending</h1>
                                    <p>Your payment is in process, Please wait.</p>
                                    {/* <p>Please check your payment information and try again.</p> */}
                                    <button class="btn btn-primary btn-block" onClick={refreshCall}>Refresh</button>
                                    {/* <a href="checkout.html" class="btn btn-danger"></a> */}
                                </div>
                            </div>
                            : (state.status == 3) ?
                                <div class="container mt-5">
                                    <div class="alert alert-danger text-center">
                                        <h1>Payment Failed</h1>
                                        <p>Your payment could not be processed at this time.</p>
                                        <p>Please check your payment information and try again.</p>
                                        <button class="btn btn-primary btn-block" onClick={tryAgainCall}>Try Again</button>
                                        {/* <a href="checkout.html" class="btn btn-danger">Try Again</a> */}
                                    </div>
                                </div>
                                :
                                <h3 class="py-3"> Transaction not found.</h3>
                    }
                </section>

            </section>


            {/* <?php include 'footer.php';?> */}
            <Footer/>
        </div>
    )
}

export default Wallet_payment_success
