import React from 'react'
import moment from 'moment'
import { useLocation, useParams } from 'react-router-dom'
import axios from "axios";
import Moment from 'react-moment';
import Barcode from 'react-barcode';


import ReactLoading from 'react-loading';
import config from "../../config"

const Manifest = () => {
    const currentDate = moment().format('DD MMMM YYYY, h:mmA');
    const location = useLocation()
  const shipment_id = useParams()
  console.log("shipment_id",shipment_id)

  let userDetail = localStorage.getItem('ship_rocket_user');
  // console.log("userDetail====", config.UserId);
  let userData = {};
  if (userDetail != null) {
    userData = JSON.parse(userDetail)
  }

  const [state, setState] = React.useState([] )
  // console.log("state",state)
  const [dimensionState, setdimensionState] = React.useState({ companyDetail: [] })
  const [isloading, setisloading] = React.useState(true)

  const [shipmentsList, setShipmentList] = React.useState([])

  React.useEffect(() => {

    shipment_list()
    dimension_detail()

  }, [])

  React.useEffect(() => {
    // console.log("ss", state.customer_label_setting.label_size_print);
    // if (state.customer_label_setting.label_size_print == "8x11") {
    //   let arr1 = [];
    //   for (let i = 0; i < state.shipment_list.length; i += 4)
    //     arr1.push(state.shipment_list.slice(i, i + 4));
    //   console.log(arr1);
    //   setShipmentList(arr1)

    // } else {
    //   console.log("else");
    //   setShipmentList(state.shipment_list)
    // }


  }, [])

  const shipment_list = () => {
    setisloading(true)
    let dataToSend = {
      customer_id: userData.customer_id, shipment_id: shipment_id.shipment_id
    };

    // console.log("datatoSend",dataToSend)
    let url = config.apiUrl + '/shipment/print_label_shipments';

    // // console.log("headers => ", config.headers);


    axios.post(url, dataToSend, { headers: config.headers })
      .then((res) => {
        // console.log("responseJson => ", res);
        setShipmentList(res.data.output )
      
        setisloading(false)
        setTimeout(() => {
          window.print();
        }, 1000)




      })
      .catch((error) => {
        //Hide Loader
        //   setLoadingstate({...loadingstate,loading:false})
        // console.log(error);
      });

  }
 

  const dimension_detail = () => {
    let full_api = config.apiUrl + `/user/get_company_info_api`;
    let sendData = {};

    axios.post(full_api, sendData, { headers: config.headers }).then(res => {
      setdimensionState({ ...dimensionState, companyDetail: res.data.companyDetail });
      // console.log("rnmmmmm", res);
    }).catch((e) => {
      // toast.configure()
      //toast.error("Some thing went wrong")
      // console.log("----error:   ", e);
    })
  }
 
  return (
    <div>
      <div class="container mt-3">
        <div class="row justify-content-between">
            {/* <div class="col-3 col-md-3">
                <img src="https://i.imgur.com/xbOGrm9.png" class="img-fluid" style={{maxHeight:"70px"}} />
            </div> */}
            <div class="col col-md text-center">
                <h2  class="m-0 p-0">Manifest</h2>
                <div class="d-flex justify-content-center">
                    <div class="p-1">Generated on {currentDate}</div>
                </div>
            </div>
            {/* <div class="col-3 col-md-3 text-end">
                <img src="https://i.imgur.com/Sx6F4aC.png" class="img-fluid" style={{maxHeight:"40px"}} />
                <h5>MID: 92DK33K33</h5>
            </div> */}
        </div>
        {/* <div class="row mt-0 justify-content-between">
            <div class="col-6 col-md-4">
                Seller: Stationery India Pvt Ltd
            </div>
            <div class="col-6 col-md-4 text-end">
                Total Shipments: 90
            </div>
        </div> */}

        <table class="table table-bordered border-dark bordered table-sm">
            <thead>
              <tr>
                <th>Sr No</th>
                <th>Order #</th>
                <th>AWB #</th>
                <th>Carrier</th>
                <th>Content</th>
                <th>Signature/Company Seal</th>
              </tr>
            </thead>
            <tbody>
            {isloading == false ?
        shipmentsList.map((sub , index) => (
              <tr>
                <td>{index+1}</td>
                <td>
                    <h6>{sub.order_number?sub.order_number:""}</h6>
                </td>
                <td class="text-center">
                    {/* <img src="https://i.imgur.com/Sx6F4aC.png" class="img-fluid" style={{maxHeight:"40px"}} /> */}
                    <Barcode value={sub.carrier_tracking_number} height={40} width={1} displayValue="false" /> 
                    <h6 class="m-0 p-0">{sub.carrier_tracking_number?sub.carrier_tracking_number:""}</h6>
                </td>
                <td>{sub.carrier_name?sub.carrier_name:""}</td>
                <td>
  {sub.items.map((item, index) => (
    <div key={index}>
      {item.item_name}
    </div>
  ))}
  <h6>
    {sub.payment_mode ? sub.payment_mode : ""} - ₹{sub.items.reduce((total, item) => total + item.item_value, 0).toFixed(2)}
  </h6>
</td>
                <td>
                    
                </td>
              </tr>
              )):<div style={{ display: 'flex', justifyContent: 'center', marginLeft: "550px", alignItems: 'center', marginTop: "-200px", height: '100vh' }}>
           <ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={60} />
         </div>}
            </tbody>
        </table>

    </div>
    </div>
  )
}

export default Manifest
