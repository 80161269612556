
// import React from 'react'
// import { useNavigate } from 'react-router-dom';
// import { Container, Row, Col, Form, Button, ProgressBar, ModalHeader, ModalTitle } from "react-bootstrap";
// // import './customer_style.css';
// import Modal from 'react-bootstrap/Modal';

// import { Tooltip as ReactTooltip } from "react-tooltip";
// import axios from "axios";
// import Swal from 'sweetalert2'
// import { useLocation } from 'react-router-dom';
// import ReactLoading from 'react-loading';
// import config from "../../config"
// import Moment from 'react-moment';
// import { CSVLink, CSVDownload } from "react-csv";

// import download from 'downloadjs'
// import Left_menu from './Left_menu';
// import Header from './Header';
// import Create_order_nav from './Create_order_nav';
// import Papa from 'papaparse';
// import { useNetworkState } from 'react-use';

// const Order_import = () => {
//   let navigate = useNavigate()
//   let userDetail = localStorage.getItem('ship_rocket_user');
//   //console.log("userDetail====", config.UserId);
//   let userData = {};
//   if (userDetail != null) {
//     userData = JSON.parse(userDetail)
//   }
//   const [recordid, setRecordid] = React.useState({ record_id: "" })
//   const [uploadModalState, setUploadModalState] = React.useState({ show: false, excel_fle: "" });
//   const [dimensionState, setdimensionState] = React.useState({ companyDetail: [] })
//   const [state, setState] = React.useState({ shipment_list: [], isLoading: true })
//   const [shipmentstate, setshipStatus] = React.useState({ shipment_status: null });
//   const [shipSelect, setShipSelect] = React.useState({ shipment_id: "", cancel_reason: "" });
//   const [modalState, setModalState] = React.useState({ show: false });
//   const [modaldtdtcState, setModaldtdcState] = React.useState({ show: false });
//   const [dtdc, setdtdc] = React.useState();
//   const [loadingstate, setLoadingstate] = React.useState(false)
//   const [summarystate, setSummarystate] = React.useState(false)
//   const [statesummary, setsummaryState] = React.useState([])
//   const [summaryloader, setSummaryLoader] = React.useState(false)
//   const [csvFileError, setCsvFileError] = React.useState([])



//   const Toast = Swal.mixin({
//     toast: true,
//     position: 'bottom-end',
//     showConfirmButton: false,
//     timer: 5000
//   })

//   //console.log("shipmentState", shipmentstate)
//   React.useEffect(() => {

//     shipment_list()
//     dimension_detail()

//   }, [])

//   const shipment_list = (status) => {
//     let dataToSend = {
//       customer_id: userData.customer_id, status: status
//     };

//     // console.log("datatoSend", dataToSend)
//     let url = config.apiUrl + '/shipment/view_order_file_summary';

//     // console.log("headers => ", config.headers);


//     axios.post(url, dataToSend, { headers: config.headers })
//       .then((res) => {
//         //   console.log("responseJson => ", res);
//         // setSummarystate(res.data.output)
//         setsummaryState(res.data.output)

//       })
//       .catch((error) => {
//         //Hide Loader
//         //   setLoadingstate({...loadingstate,loading:false})
//         console.log(error);
//       });

//   }

//   const dimension_detail = () => {
//     let full_api = config.apiUrl + `/user/get_company_info_api`;
//     let sendData = {};

//     axios.post(full_api, sendData, { headers: config.headers }).then(res => {
//       setdimensionState({ ...dimensionState, companyDetail: res.data.companyDetail });
//       // console.log("rnmmmmm", res);
//     }).catch((e) => {
//       // toast.configure()
//       //toast.error("Some thing went wrong")
//       console.log("----error:   ", e);
//     })
//   }

//   const shipmentFilter = (status) => {
//     console.log("action ", status);
//     setshipStatus({ ...shipmentstate, shipment_status: status })
//     shipment_list(status)
//   }

//   const cancelModal = (ship) => {
//     console.log("----ship:   ", ship.shipment_id);
//     setShipSelect({ cancel_reason: "", shipment_id: ship.shipment_id });
//     setModalState({ show: true })

//   }

//   const cancelShipment = () => {
//     if (shipSelect.cancel_reason != "") {
//       let full_api = config.apiUrl + `/shipment/shipment_cancel`;
//       let sendData = { customer_id: userData.customer_id, shipment_id: shipSelect.shipment_id, cancel_reason: shipSelect.cancel_reason };

//       axios.post(full_api, sendData, { headers: config.headers }).then(res => {
//         Toast.fire({
//           background: "#10b93b",
//           type: 'success',
//           title: res.data.message,
//           color: "white"
//         });
//         if (res.data.status == "success") {

//           setShipSelect({ cancel_reason: "", shipment_id: "" });
//           setModalState({ show: false })
//           shipment_list()
//         }
//         // console.log("rnmmmmm", res);
//       }).catch((e) => {
//         // toast.configure()
//         //toast.error("Some thing went wrong")
//         console.log("----error:   ", e);
//       })
//     } else {
//       Toast.fire({
//         background: "#10b93b",
//         type: 'success',
//         title: "Enter Reason to Cancel.",
//         color: "white"
//       });
//     }
//   }

//   const inputHandleChange = (e) => {
//     const value = e.target.value;
//     const text = e.target.name;
//     setShipSelect({ ...shipSelect, [text]: value })
//   }

//   const shipping_label = (sub) => {
//     console.log("sub ==== ", sub)
//     if (sub.carrier_id == "1656377059") {
//       let config = {
//         method: 'get',
//         maxBodyLength: Infinity,
//         url: 'https://dtdcapi.shipsy.io/api/customer/integration/consignment/shippinglabel/stream?reference_number=' + sub.carrier_tracking_number,
//         responseType: 'blob',
//         headers: {
//           'Content-Type': 'application/pdf',
//           'api-key': 'e96fb203eef06314303c1aa7ef8c0c'
//         }
//       };

//       axios.request(config)
//         .then((response) => {
//           console.log((response.data));
//           download(response.data, 'dtdc.pdf')

//         })
//         .catch((error) => {
//           console.log(error);
//         });

//     }
//   }

//   const csvinputEditHandleChange = async (e) => {
//     setCsvFileError([])
//     // console.log("target ", e.target.files);
//     // const file = e.target.files[0];
//     if (e.target.files.length > 0) {
//       // var patt1 = /\.([0-9a-z]+)(?:[\?#]|$)/i;
//       let fileName = e.target.files[0].name;
//       // console.log("fileName =======>                 ",fileName);

//       // console.log("file =======>                 ",fileName.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)[0]);
//       // return 
//       if (fileName.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)[0] == ".csv") {
//         Papa.parse(e.target.files[0], {
//           header: true,
//           skipEmptyLines: true,
//           complete: function (results) {
//             // console.log("re => ", results.data)
//             checkCsvFile(results.data, e.target.files)
//           },
//         });
//       } else {
//         const Toast = Swal.mixin({
//           toast: true,
//           position: 'bottom-end',
//           showConfirmButton: false,
//           timer: 3000
//         })
//         Toast.fire({
//           background: "#ff0000",
//           type: 'error',
//           title: "Please choose CSV file!",
//           color: "white"
//         });

//         setLoadingstate(false)
//       }
//       //const fileUrl = URL.createObjectURL(file);

//       // 2. use fetch API to read the file
//       //const response = await fetch(fileUrl);

//       // 3. get the text from the response
//       // const text = await response.text();

//       // 4. split the text by newline
//       // const lines = text.split("\n");

//       // 5. map through all the lines and split each line by comma.
//       // const _data = lines.map((line) => line.split(","));
//       // if (e.target.type == "file") {

//       //  setUploadModalState({ ...uploadModalState, [e.target.name]: e.target.files });
//       //  } else {
//       // setUploadModalState({ ...uploadModalState, [e.target.name]: e.target.value });
//       //}
//     }
//   }


// const checkCsvFile = (data, file) => {
//   let rowLength = 17;
//   let validFile = true;
//   let pincodeLength = 6;
//   let mobileLength = 10;
//   console.log("length ", data.length)
//   let keyFields = ["Order_ID", "Order_Date", "Warehouse_Name", "Receiver_Name", "Receiver_Address", "Receiver_Pincode", "Receiver_Email", "Receiver_Mobile", "Item_Name", "Height", "Width", "Length", "Weight", "Item_Value", "Item_Quantity", "Payment_Mode", "COD_Value"]
//   let csvError = [keyFields];
//   data.map((item, index) => {
//     let isValid = true;
//     let rowError = ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""];
//     console.log("item", Object.keys(item))
//     if (Object.keys(item).length == rowLength) {
//       Object.keys(item).map((row, i) => {
//         // rowError[i] = "No val";
//         rowError[i] = item[row]
//         if (keyFields[i] == row) {
//           /*Blank value Check */
//           console.log(keyFields[i], " ", item[row]);
//           if (item[row]) {
//             if (row == "Receiver_Pincode" && item[row].length != pincodeLength) {
//               csvError.push(item);
//               isValid = false;
//               rowError[i] = "pincode not valid";
//               console.log("pincode not valid ", keyFields[i], " ", item[row]);
//               validFile = false;
//             }
//             if (row == "Receiver_Mobile" && item[row].length != mobileLength) {
//               isValid = false;
//               rowError[i] = "mobile number not valid";
//               console.log("mobile number not valid ", keyFields[i], " ", item[row]);
//               validFile = false;
//             }
//             if (row == "Receiver_Email" && !item[row].match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
//               isValid = false;
//               rowError[i] = "email not valid";
//               console.log("email not valid ", keyFields[i], " ", item[row]);
//               validFile = false;
//             }
//             if (row == "Payment_Mode" && item[row] == "COD" && item["COD_Value"] == "") {
//               console.log("cod value ", index + 2, "", keyFields[i], " ", item[row]);
//               isValid = false;
//               rowError[i] = "No cod value";
//               validFile = false;

//             }
//             if (keyFields[i] == "Width" && item[row].length != mobileLength) {
//               console.log(item[row].length);
//               //validFile = false;
//             }
//             if (keyFields[i] == "Length" && item[row].length != mobileLength) {
//               console.log(item[row].length);
//               //  validFile = false;
//             }
//             if (keyFields[i] == "Weight" && item[row].length != mobileLength) {

//               console.log(item[row].length);
//               // validFile = false;
//             }
//           } else {
//             if (row != "COD_Value") {
//               isValid = false;
//               rowError[i] = "Blank Value";
//             }
//             console.log("blank value ", index + 2, " ", keyFields[i], " ", i, " ", item[row]);
//             validFile = false;
//           }
//         } else {
//           validFile = false;
//         }

//       })
//     } else {
//       validFile = false;
//     }
//     if (!isValid) {
//       csvError.push(rowError);
//     }
//   })
//   setCsvFileError(csvError)
//   console.log("csvError == ", csvError);

//   console.log(validFile);
//   if (validFile) {
//     console.log("is valid");
//     setUploadModalState({ excel_fle: file, show: false })
//   } else {
//     const Toast = Swal.mixin({
//       toast: true,
//       position: 'bottom-end',
//       showConfirmButton: false,
//       timer: 3000
//     })
//     Toast.fire({
//       background: "#ff0000",
//       type: 'error',
//       title: "File Data is not correct!",
//       color: "white"
//     });
//   }

// }
//   const csvData = [
//     ["firstname", "lastname", "email"],
//     ["Ahmed", "Tomi", "ah@smthing.co.com"],
//     ["Raed", "Labes", "rl@smthing.co.com"],
//     ["Yezzi", "Min l3b", "ymin@cocococo.com"]
//   ];

//   const uploadOrdersFile = () => {
//     setLoadingstate(true)
//     console.log("uploadModalState", uploadModalState);
//     if (uploadModalState.excel_fle != undefined && uploadModalState.excel_fle != '') {
//       let full_api = config.apiUrl + `/shipment/upload_bulk_orders`;
//       let bodyFormData = new FormData()
//       // console.log("bb", editState);

//       bodyFormData.append("customer_id", userData.customer_id)
//       bodyFormData.append("excel_fle", uploadModalState.excel_fle[0]);

//       // let bodyData = {country_id:country_id,csvFile:uploadModalState.csv_fle}
//       console.log("bb", bodyFormData);
//       axios.post(full_api, bodyFormData, {
//         headers: config.headers
//       }).then((res) => {
//         console.log("res ", res);

//         // setUploadModalState({...uploadModalState, show: false });
//         //  setEditState({ ...editState, state_name: "", state_id: "" })


//         if (res.status == 200 && res.data.status == "success") {
//           setLoadingstate(false)
//           setSummarystate(true)
//           setRecordid({ record_id: res.data.record_id })
//           // setTimeout(getStateList(0,true), 5000);
//           const Toast = Swal.mixin({
//             toast: true,
//             position: 'bottom-end',
//             showConfirmButton: false,
//             timer: 3000
//           })
//           Toast.fire({
//             background: "#206bc4",
//             type: 'success',
//             title: "Bulk Import Starts",
//             color: "white"
//           });
//           // shipment_list()
//           // setTimeout(()=>{
//           //   navigate(`/import_process/${product_id}/${carrier_id}/${source_city_id}`)
//           // },1000)

//         }

//         else {
//           Swal.fire({
//             icon: 'error',
//             title: 'Oops...',
//             text: 'Something went wrong!',

//           })
//         }

//         // navigate('/categories')
//         // let newCategory = res.data.category;
//         //setState(state.concat([newCategory]))
//       }).catch((e) => {

//         Swal.fire({
//           icon: 'error',
//           title: 'Oops...',
//           text: 'Something went wrong!',

//         })
//       });
//     } else {
//       const Toast = Swal.mixin({
//         toast: true,
//         position: 'bottom-end',
//         showConfirmButton: false,
//         timer: 3000
//       })
//       Toast.fire({
//         background: "#ff0000",
//         type: 'error',
//         title: "Please choose a file!",
//         color: "white"
//       });
//       setLoadingstate(false)
//     }



//   }
//   const closeModal = () => {
//     // setUploadModalState({ ...uploadModalState, show: false })
//     shipment_list()
//   }
//   const Summary = () => {
//     setSummaryLoader(true)
//     let dataToSend = {
//       customer_id: userData.customer_id
//     };

//     console.log("datatoSend", dataToSend)
//     let url = config.apiUrl + '/shipment/view_order_file_summary';

//     // console.log("headers => ", config.headers);


//     axios.post(url, dataToSend, { headers: config.headers })
//       .then((res) => {
//         console.log("responseJson => ", res);
//         if (res.data.status == true) {
//           setsummaryState(res.data.output)
//           setSummaryLoader(false)
//         }
//       })
//       .catch((error) => {
//         //Hide Loader
//         //   setLoadingstate({...loadingstate,loading:false})
//         console.log(error);
//       });



//   }
//   return (
//     <div>
//       {/* <?php include 'meta.php';?> */}

//       {/* <?php include 'left_menu.php';?> */}
//       <Left_menu />


//       <section class="home ">
//         {/* <?php include 'header.php';?>  */}
//         <Header />

//         {/* <?php include 'create_order_nav.php';?>  */}
//         <Create_order_nav value={2} />


//         <section class="mb-3">
//           <div class="container">
//             <div class="row">
//               <div class="col-12 col-md">
//                 <h4 class="pb-3">Bulk Orders</h4>
//                 <div class="card border-0 order_box mb-4">
//                   <div class="card-body">
//                     <section class="pb-5">
//                       <div class="row pb-4">
//                         <div class="col-12 col-md">
//                           <h6 class="mb-3">Import Bulk Orders</h6>
//                           <div class="alert alert-primary">
//                             <div class="d-flex">
//                               <div class=""><h3><i class="fa fa-lightbulb-o" aria-hidden="true"></i></h3></div>
//                               <div class="ps-4 ">Download the sample file and replace its data with your order data. <br />Make sure all mandatory fields are filled.
//                                 Save the file and upload it back.</div>
//                             </div>
//                           </div>
//                         </div>
//                         <div class="col-12 col-md-3">
//                           <div class="card border-0 bg-light p-3">
//                             <div class="card-body">
//                               <div class="d-grid">
//                                 <a href="https://cdn.connectingmiles.co.in/sample-template/bulk-order-template.csv" target="_blank" class="btn btn-primary btn-block">Download Sample File</a>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                       <div class="row">
//                         <div class="col-12 col-md mb-3 fileinput">
//                           <div class="card text-center border-primary border-3 rounded-3">
//                             <div class="card-body">
//                               <label class="custom-file-upload">
//                                 <input type="file" name="excel_fle" onChange={(e) => csvinputEditHandleChange(e)} />
//                                 <i class="fa fa-cloud-upload"></i> Browse and Upload
//                               </label>
//                               <p><small>Only csv file format is acceptable</small></p>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                       <div style={{ float: "right" }}>
//                         {loadingstate == false && summarystate == false ? <a class="btn btn-primary btn-block" onClick={() => uploadOrdersFile()} >Submit</a> : loadingstate == true && summarystate == false ? <ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={40} /> : summarystate == true ? <a class="btn btn-primary btn-block" href={`/summary/${recordid.record_id}`} >View Summary</a> : ""}
//                       </div>
//                       {(csvFileError.length > 0) ?
//                         <div class="col-12 col-md-8 alerts_box ">
//                           <div class="alert alert-danger mb-3">
//                             <div class="row">

//                               <div class="col-12 col-md">
//                                 <div class="d-flex ">
//                                   <div class="">
//                                     <i class="material-icons-outlined">error_outline</i>
//                                   </div>
//                                   <div class="ps-2 ">
//                                     <h5>File could not be processed</h5>
//                                     <p>Please fix the errors and re-upload</p>
//                                   </div>
//                                 </div>
//                               </div>
//                               <div class="col-12 col-md-4">
//                                 <CSVLink data={csvFileError} filename={"error_log.csv"} class="btn btn-outline-danger btn-sm">
//                                   Download Error Report</CSVLink>

//                               </div>


//                             </div>
//                           </div>
//                         </div>
//                         : ""}
//                     </section>
//                   </div>
//                 </div>
//                 <div class="card border-0 order_box mb-4">
//                   <div class="card-body">
//                     <section class="pb-5">
//                       <div class="alert alert-light">
//                         <small>The successful orders will be directed to Process Orders. You can download your error files from below. If you cannot find the file you’re looking for, Please go to <a href="">Activity Log</a> for the same.</small>
//                       </div>
//                       <div class="row pb-4">
//                         <div class="col-12 col-md">
//                           <h6 class="mb-3">Recent Uploads</h6>
//                           <div class="table-responsive upload_table">
//                             <table class="table">
//                               <thead>
//                                 <tr>
//                                   <th>File Name</th>
//                                   <th>Date</th>
//                                   <th>Total Orders</th>
//                                   <th>Uploaded Orders</th>
//                                   {/* <th>Error Orders</th> */}
//                                   <th>Log</th>
//                                 </tr>
//                               </thead>
//                               {summaryloader ? <ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={40} /> :
//                                 <tbody>
//                                   {statesummary.map((sub) => (
//                                     <tr>
//                                       <td><a href="">{sub.file_name}</a></td>
//                                       <td><Moment format="MMM DD YYYY">
//                                         {new Date(sub.start_time * 1000)}
//                                       </Moment> - <Moment format="hh:mm:ss a">
//                                           {new Date(sub.start_time * 1000)}
//                                         </Moment> </td>
//                                       <td>{sub.total_record}</td>
//                                       <td>{sub.uploaded_record}</td>
//                                       {/* <td>09</td> */}
//                                       <td><a href={`/summary/${recordid.record_id ? recordid.record_id : sub.record_id}`}>Import log <i class="fa fa-long-arrow-right" aria-hidden="true"></i></a></td>
//                                     </tr>
//                                   ))}
//                                 </tbody>
//                               }
//                             </table>
//                           </div>
//                         </div>
//                       </div>
//                     </section>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>

//       </section>



//     </div>
//   )
// }

// export default Order_import

import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ProgressBar, ModalHeader, ModalTitle } from "react-bootstrap";
// import './customer_style.css';
import Modal from 'react-bootstrap/Modal';

import { Tooltip as ReactTooltip } from "react-tooltip";
import axios from "axios";
import Swal from 'sweetalert2'
import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../../config"
import Moment from 'react-moment';

import download from 'downloadjs'
import Left_menu from './Left_menu';
import Header from './Header';
import Create_order_nav from './Create_order_nav';
import Papa from 'papaparse';
import { useNetworkState } from 'react-use';
import { CSVLink, CSVDownload } from "react-csv";
import Footer from '../Footer';

const Order_import = () => {
  let navigate = useNavigate()
  const isOnline = useNetworkState();
  // console.log("isONline  === ", isOnline)
  let userDetail = localStorage.getItem('ship_rocket_user');
  // console.log("userDetail====", config.UserId);
  let userData = {};
  if (userDetail != null) {
    userData = JSON.parse(userDetail)
  }
  const [recordid, setRecordid] = React.useState({ record_id: "" })
  const [uploadModalState, setUploadModalState] = React.useState({ show: false, excel_fle: "" });
  const [dimensionState, setdimensionState] = React.useState({ companyDetail: [] })
  const [state, setState] = React.useState({ shipment_list: [], isLoading: true })
  const [shipmentstate, setshipStatus] = React.useState({ shipment_status: null });
  const [shipSelect, setShipSelect] = React.useState({ shipment_id: "", cancel_reason: "" });
  const [modalState, setModalState] = React.useState({ show: false });
  const [modaldtdtcState, setModaldtdcState] = React.useState({ show: false });
  const [uploadState, setUploadState] = React.useState({ status: "" });
  const [csvFileError, setCsvFileError] = React.useState([])
  const [dtdc, setdtdc] = React.useState();
  const [loadingstate, setLoadingstate] = React.useState(false)
  const [summarystate, setSummarystate] = React.useState(false)
  const [statesummary, setsummaryState] = React.useState([])
  const [summaryloader, setSummaryLoader] = React.useState(false)
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
  
  const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
      const homeSection = document.querySelector('.home-section');
      if (homeSection) {
          homeSection.classList.toggle('sidebar-closed');
      }
  };


  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000
  })

  // console.log("shipmentState",shipmentstate)
  React.useEffect(() => {

    shipment_list()
    dimension_detail()

  }, [])

  const shipment_list = (status) => {
    let dataToSend = {
      customer_id: userData.customer_id, status: status
    };

    // console.log("datatoSend",dataToSend)
    let url = config.apiUrl + '/shipment/view_order_file_summary';

    // // console.log("headers => ", config.headers);


    axios.post(url, dataToSend, { headers: config.headers })
      .then((res) => {
        // console.log("responseJson => ", res);
        // setSummarystate(res.data.output)
        setsummaryState(res.data.output)

      })
      .catch((error) => {
        //Hide Loader
        //   setLoadingstate({...loadingstate,loading:false})
        // console.log(error);
      });

  }

  const dimension_detail = () => {
    let full_api = config.apiUrl + `/user/get_company_info_api`;
    let sendData = {};

    axios.post(full_api, sendData, { headers: config.headers }).then(res => {
      setdimensionState({ ...dimensionState, companyDetail: res.data.companyDetail });
      // console.log("rnmmmmm", res);
    }).catch((e) => {
      // toast.configure()
      //toast.error("Some thing went wrong")
      // console.log("----error:   ", e);
    })
  }

  // const shipmentFilter = (status) => {
  //   // console.log("action ", status);
  //   setshipStatus({ ...shipmentstate, shipment_status: status })
  //   shipment_list(status)
  // }

  // const cancelModal = (ship) => {
  //   // console.log("----ship:   ", ship.shipment_id);
  //   setShipSelect({ cancel_reason: "", shipment_id: ship.shipment_id });
  //   setModalState({ show: true })

  // }

  // const cancelShipment = () => {
  //   if (shipSelect.cancel_reason != "") {
  //     let full_api = config.apiUrl + `/shipment/shipment_cancel`;
  //     let sendData = { customer_id: userData.customer_id, shipment_id: shipSelect.shipment_id, cancel_reason: shipSelect.cancel_reason };

  //     axios.post(full_api, sendData, { headers: config.headers }).then(res => {
  //       Toast.fire({
  //         background: "#10b93b",
  //         type: 'success',
  //         title: res.data.message,
  //         color: "white"
  //       });
  //       if (res.data.status == "success") {

  //         setShipSelect({ cancel_reason: "", shipment_id: "" });
  //         setModalState({ show: false })
  //         shipment_list()
  //       }
  //       // console.log("rnmmmmm", res);
  //     }).catch((e) => {
  //       // toast.configure()
  //       //toast.error("Some thing went wrong")
  //       // console.log("----error:   ", e);
  //     })
  //   } else {
  //     Toast.fire({
  //       background: "#10b93b",
  //       type: 'success',
  //       title: "Enter Reason to Cancel.",
  //       color: "white"
  //     });
  //   }
  // }

  // const inputHandleChange = (e) => {
  //   const value = e.target.value;
  //   const text = e.target.name;
  //   setShipSelect({ ...shipSelect, [text]: value })
  // }

  // const shipping_label = (sub) => {
  //   // console.log("sub ==== " , sub)
  //   if (sub.carrier_id == "1656377059") {
  //     let config = {
  //       method: 'get',
  //       maxBodyLength: Infinity,
  //       url: 'https://dtdcapi.shipsy.io/api/customer/integration/consignment/shippinglabel/stream?reference_number=' + sub.carrier_tracking_number,
  //       responseType: 'blob',
  //       headers: {
  //         'Content-Type': 'application/pdf',
  //         'api-key': 'e96fb203eef06314303c1aa7ef8c0c'
  //       }
  //     };

  //     axios.request(config)
  //       .then((response) => {
  //         // console.log((response.data));
  //         download(response.data, 'dtdc.pdf')

  //       })
  //       .catch((error) => {
  //         // console.log(error);
  //       });

  //   }
  // }

  const csvinputEditHandleChange = (e) => {
    // console.log(e.target.files[0].type);
    if (e.target.type == "file") {
      setCsvFileError([])
      if (e.target.files.length > 0) {
        // setUploadModalState({ ...uploadModalState, [e.target.name]: e.target.files });
        if (e.target.files[0].type == "text/csv") {
          Papa.parse(e.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
              // console.log("re => ", results.data)
              checkCsvFile(results.data, e.target.files)
            },
          });
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000
          })
          Toast.fire({
            background: "#ff0000",
            type: 'error',
            title: "Please select CSV file only",
            color: "white"
          });
        }
      }
    } else {
      setUploadModalState({ ...uploadModalState, [e.target.name]: e.target.value });
    }
  }

  const checkCsvFile = (data, file) => {

    let rowLength = 17;
    let validFile = true;
    let pincodeLength = 6;
    let mobileLength = 10;
    // console.log("length ", data.length)
    let keyFields = ["Order_ID", "Warehouse_Name", "Receiver_Name", "Receiver_Address", "Receiver_Pincode", "Receiver_Email", "Receiver_Mobile", "Item_Name", "Height", "Width", "Length", "Weight", "Item_Value", "Item_Quantity", "Payment_Mode", "COD_Value"]
    let csvError = [keyFields];
    data.map((item, index) => {
      let isValid = true;
      let rowError = ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "mode", "value"];
      //  console.log(rowError.length, " ", keyFields.length)
      if (Object.keys(item).length == keyFields.length) {
        Object.keys(item).map((row, i) => {
          // rowError[i] = "No val";
          //  console.log(item[keyFields[i]], " - ", item[row], " row ", row, " ,", i);
          rowError[i] = item[row]
          if (keyFields[i] == row) {
            /*Blank value Check */
            //  console.log(keyFields[i], " ", item[row]);
            if (item[row]) {
              if (row == "Receiver_Pincode" && item[row].length != pincodeLength) {
                csvError.push(item);
                isValid = false;
                rowError[i] = "pincode not valid";
                console.log("pincode not valid ", keyFields[i], " ", item[row]);
                validFile = false;
              }
              if (row == "Receiver_Mobile" && item[row].length != mobileLength) {
                isValid = false;
                rowError[i] = "mobile number not valid";
                console.log("mobile number not valid ", keyFields[i], " ", item[row]);
                validFile = false;
              }
              if (row == "Receiver_Email" && !item[row].match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                isValid = false;
                rowError[i] = "email not valid";
                //  console.log("email not valid ", keyFields[i], " ", item[row]);
                validFile = false;
              }

             
              if (row == "Payment_Mode" && item[row] == "") {

              }
              // console.log(row, " => ", item[row])
              if (row == "COD_Value" && item[row] == "" && (item[keyFields[i - 1]] == "COD" || item[keyFields[i - 1]] == "cod")) {
                //  console.log("cod value ", index + 2, "", keyFields[i], " ", item[row]);
                //  console.log(rowError[i])
                // isValid = false;
                //rowError[i] = "No cod value";
                // console.log(rowError[i])
                //validFile = false;

              }
              if (keyFields[i] == "Width" && item[row].length != mobileLength) {
                //  console.log(item[row].length);
                //validFile = false;
              }
              if (keyFields[i] == "Length" && item[row].length != mobileLength) {
                // console.log(item[row].length);
                //  validFile = false;
              }
              if (keyFields[i] == "Weight" && item[row].length != mobileLength) {

                //  console.log(item[row].length);
                // validFile = false;
              }
            } else {
              if (row != "COD_Value") {
                isValid = false;
                rowError[i] = "BLANK VALUE";
              } else {
                // console.log("blank ", item[keyFields[i]] == "")
                if ((item[keyFields[i - 1]] == "cod" || item[keyFields[i - 1]] == "COD") && item[keyFields[i]] == "") {
                  isValid = false;
                  rowError[i] = "BLANK VALUE";
                  validFile = false;
                }

              }
              //  console.log("blank value ", index + 2, " ", keyFields[i], " ", i, " ", item[row]);
              // validFile = false;
            }
          } else {
            validFile = false;
          }

        })
      } else {
        validFile = false;
      }
      if (!isValid) {
        // console.log(rowError);
        csvError.push(rowError);
      }
    })

    //  console.log("csvError == ", csvError);

    //  console.log(validFile);
    if (validFile) {

      // console.log("is valid");
      setUploadModalState({ excel_fle: file, show: false })
    } else {
      // console.log("csvError == ", csvError);
      setCsvFileError(csvError)
      const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
      })
      Toast.fire({
        background: "#ff0000",
        type: 'error',
        title: "File Data is not correct!",
        color: "white"
      });
    }

  }
  const uploadOrdersFile = () => {
    setLoadingstate(true)
    if (uploadModalState.excel_fle != undefined && uploadModalState.excel_fle != '') {
      // var patt1 = /\.([0-9a-z]+)(?:[\?#]|$)/i;
      let fileName = uploadModalState.excel_fle[0].name;
      // // console.log("fileName =======>                 ",fileName);

      // // console.log("file =======>                 ",fileName.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)[0]);
      // return 
      if (fileName.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)[0] == ".csv") {
        Papa.parse(uploadModalState.excel_fle[0], {
          complete: (result) => {
            const dataLength = result.data.length;
            // console.log("dataLength == " , dataLength)

            if (dataLength <= 1001) {
              let full_api = config.apiUrl + `/shipment/upload_bulk_orders`;
              let bodyFormData = new FormData()
              // // console.log("bb", editState);

              bodyFormData.append("customer_id", userData.customer_id)



              bodyFormData.append("excel_fle", uploadModalState.excel_fle[0]);

              // let bodyData = {country_id:country_id,csvFile:uploadModalState.csv_fle}
              // console.log("bb", bodyFormData);
              let header = config.headers;
              header.Accept = "text/event-stream";
              console.log(header);
              axios({
                method: "post",
                url: full_api,
                headers: header,
                data: bodyFormData,
                // responseType: "stream",
                // onDownloadProgress: progressEvent => {
                //    console.log("progressEvent ", progressEvent.currentTarget.response);
                //   let json = progressEvent.currentTarget.response.split("Z");
                //   json = JSON.parse(json[json.length - 1]);
                //   // console.log(json)
                //   if (json.status != "pending") {

                //     // console.log(v)

                //     Toast.fire({
                //       background: "#206bc4",
                //       type: 'success',
                //       title: "Bulk Import Finished",
                //       color: "white"
                //     });
                //     shipment_list()
                //     setLoadingstate(false)
                //     setSummarystate(true)
                //     setRecordid({ record_id: json.data })
                //     setUploadState({ ...uploadState, status: "" })

                //   } else {
                //     // console.log(json.data);
                //     let v = json.data.split("S");
                //     v = v[v.length - 1];
                //     setUploadState({ ...uploadState, status: v })
                //     setRecordid({ record_id: json.record_id })
                //   }

                // }
              }).then((res) => {
                console.log("res ", res);

                // setUploadModalState({...uploadModalState, show: false });
                //  setEditState({ ...editState, state_name: "", state_id: "" })


                if (res.status == 200) {
                  if (res.data.status == "success") {
                    setLoadingstate(false)
                    setSummarystate(true)
                    setRecordid({ record_id: res.data.record_id })
                    Toast.fire({
                      background: "#206bc4",
                      type: 'success',
                      title: "Bulk Import Process Starts",
                      color: "white"
                    });
                    // setTimeout(getStateList(0,true), 5000);
                  } else {
                    //setUploadState(res.data)
                  }


                  // shipment_list()
                  // setTimeout(()=>{
                  //   navigate(`/import_process/${product_id}/${carrier_id}/${source_city_id}`)
                  // },1000)

                }

                else {
                  console.log("res unsuccess");
                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',

                  })
                }

                // navigate('/categories')
                // let newCategory = res.data.category;
                //setState(state.concat([newCategory]))
              }).catch((e) => {
                console.log("error  ", e);
                // Swal.fire({
                //   icon: 'error',
                //   title: 'Oops...',
                //   text: 'catch Something went wrong!',

                // })
              });
            }
            else {
              setLoadingstate(false)
              setSummarystate(false)
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Not Allowed to Import more than 1000 orders in one file ',

              })
            }
          }
        })

      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 3000
        })
        Toast.fire({
          background: "#206bc4",
          type: 'error',
          title: "Please choose CSV file!",
          color: "white"
        });
        setLoadingstate(false)
      }
    } else {
      const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
      })
      Toast.fire({
        background: "#206bc4",
        type: 'error',
        title: "Please choose a file!",
        color: "white"
      });
      setLoadingstate(false)
    }


  }
  // const closeModal = () => {
  //   setUploadModalState({ ...uploadModalState, show: false })
  //   shipment_list()
  // }
  // const Summary = () => {
  //   setSummaryLoader(true)
  //   let dataToSend = {
  //     customer_id: userData.customer_id
  //   };

  //   // console.log("datatoSend",dataToSend)
  //   let url = config.apiUrl + '/shipment/view_order_file_summary';

  //   // // console.log("headers => ", config.headers);


  //   axios.post(url, dataToSend, { headers: config.headers })
  //     .then((res) => {
  //       // console.log("responseJson => ", res);
  //       if (res.data.status == true) {
  //         setsummaryState(res.data.output)
  //         setSummaryLoader(false)
  //       }
  //     })
  //     .catch((error) => {
  //       //Hide Loader
  //       //   setLoadingstate({...loadingstate,loading:false})
  //       // console.log(error);
  //     });



  // }
  return (
    <div>
      {/* <?php include 'meta.php';?> */}

      {/* <?php include 'left_menu.php';?> */}
      <Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <div className={`home-section ${isSidebarOpen ? '' : 'sidebar-closed'}`}>
         
          <Header/>
        {/* <?php include 'create_order_nav.php';?>  */}
        <Create_order_nav value={2} />


        <section class="mb-3">
          <div class="container">
            <div class="row">
              <div class="col-12 col-md">
                <h4 class="pb-3">Bulk Orders</h4>
                <div class="card border-0 order_box mb-4">
                  <div class="card-body">
                    <section class="pb-5">
                      <div class="row pb-4">
                        <div class="col-12 col-md">
                          <h6 class="mb-3">Import Bulk Orders</h6>
                          <div class="alert alert-primary">
                            <div class="d-flex">
                              <div class=""><h3><i class="fa fa-lightbulb-o" aria-hidden="true"></i></h3></div>
                              <div class="ps-4 ">Download the sample file and replace its data with your order data. <br />Make sure all mandatory fields are filled.
                                Save the file and upload it back.</div>
                            </div>
                            <div class="d-flex mt-2">
                              <div class=""><h3><i class="fa fa-lightbulb-o" aria-hidden="true"></i></h3></div>
                              <div class="ps-4 ">You can import only 1000 orders in one file.</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-3">
                          <div class="card border-0 bg-light p-3">
                            <div class="card-body">
                              <div class="d-grid">
                                <a href="../../../assets/doc/CONNECTINGMILES_BULK_ORDER_UPLOAD.csv"  class="btn btn-primary btn-block">Download Sample File</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 col-md mb-3 fileinput">
                          <div class="card text-center border-primary border-3 rounded-3">
                            <div class="card-body">
                              <label class="custom-file-upload">
                                <input type="file" name="excel_fle" onChange={(e) => csvinputEditHandleChange(e)} />
                                <i class="fa fa-cloud-upload"></i> Browse and Upload
                              </label>
                              <p><small>Only csv file format is acceptable</small></p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style={{ float: "left" }}>
                        {(uploadState.status != "") ?
                          uploadState.status + " Uploaded" : ""}
                      </div>
                      <div style={{ float: "right" }}>
                        {loadingstate == false ? (recordid.record_id != "") ? <div class="col-12 col-md-4">
                          <a href={'/summary/' + recordid.record_id} class="btn btn-primary btn-block">View summary</a>
                        </div> : <a class="btn btn-primary btn-block" onClick={() => uploadOrdersFile()} >Submit</a> : loadingstate == true && summarystate == false ? <ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={40} /> : ""}

                      </div>
                    </section>
                  </div>
                </div>
                {/* <div className='card border-0 order_box mb-4'>

                  {(csvFileError.length > 0) ?
                    <div class="col-12 col-md-8 alerts_box ">
                      <div class="alert alert-danger mb-3">
                        <div class="row">

                          <div class="col-12 col-md">
                            <div class="d-flex ">
                              <div class="">
                                <i class="material-icons-outlined">error_outline</i>
                              </div>
                              <div class="ps-2 ">
                                <h5>File could not be processed</h5>
                                <p>Please fix the errors and re-upload</p>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-md-4">
                            <CSVLink data={csvFileError} filename={"error_log.csv"} class="btn btn-outline-danger btn-sm">
                              Download Error Report</CSVLink>

                          </div>


                        </div>
                      </div>
                    </div>
                    : (loadingstate) ? <div class="alert alert-secondary">
                      <div class="row">
                        <div class="col-12 col-md">
                          <div class="d-flex ">
                            <div class="">
                              <i class="material-icons-outlined">info</i>
                            </div>
                            <div class="ps-2 ">
                              <h5>This task will run in the background</h5>
                              <p>We'll notify you once it done</p>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-4">
                          <a href={'/summary/' + recordid.record_id} class="btn btn-outline-dark btn-sm">Go to summary</a>
                        </div>
                      </div>
                    </div> : ""}
                </div> */}
                {/* {(summarystate == true) ? <div><a class="btn btn-primary btn-block" href={`/summary/${recordid.record_id}`} >View Summary</a></div> :""} */}
                <div class="card border-0 order_box mb-4">
                  <div class="card-body">
                    <section class="pb-5">
                      <div class="alert alert-light">
                        <small>The successful orders will be directed to Process Orders. You can download your error files from below. If you cannot find the file you’re looking for, Please go to <a href="">Activity Log</a> for the same.</small>
                      </div>
                      <div class="row pb-4">
                        <div class="col-12 col-md">
                          <h6 class="mb-3">Recent Uploads</h6>
                          <div class="table-responsive upload_table">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th>File Name</th>
                                  <th>Date</th>
                                  <th>Total Orders</th>
                                  <th>Uploaded Orders</th>
                                  {/* <th>Error Orders</th> */}
                                  <th>Log</th>
                                </tr>
                              </thead>
                              {summaryloader ? <ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={40} /> :
                                <tbody>
                                  {statesummary.map((sub) => (
                                    <tr>
                                      <td><a href="">{sub.file_name}</a></td>
                                      <td><Moment format="MMM DD YYYY">
                                        {new Date(sub.start_time * 1000)}
                                      </Moment> - <Moment format="hh:mm:ss a">
                                          {new Date(sub.start_time * 1000)}
                                        </Moment> </td>
                                      <td>{sub.total_record}</td>
                                      <td>{sub.uploaded_record}</td>
                                      {/* <td>09</td> */}
                                      <td><a href={`/summary/${recordid.record_id ? recordid.record_id : sub.record_id}`}>Import log <i class="fa fa-long-arrow-right" aria-hidden="true"></i></a></td>
                                    </tr>
                                  ))}
                                </tbody>
                              }
                            </table>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </div>


<Footer/>
    </div>
  )
}

export default Order_import
